import * as React from "react";

const SvgRoofTiles = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.053 6H5.158L2 18l2.526-.632L7.053 18l2.526-.632 2.526.632 2.527-.632 2.526.632 2.526-.632L22.21 18 19.053 6Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="m4.526 9.79 1.895-1.264L7.684 9.79l2.527-1.264 1.894 1.264L14 8.526l1.895 1.264 1.895-1.264 1.894 1.264M3.895 13.579l2.053-1.263 1.368 1.263 2.737-1.263 2.052 1.263 2.053-1.263 2.053 1.263 2.052-1.263 2.053 1.263M8.316 6 7.053 18M12.105 6.632V18M15.895 6l1.263 12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgRoofTiles;
