import * as React from "react";

const SvgArrowRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="arrow-right_svg__h-6 arrow-right_svg__w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m14 5 7 7m0 0-7 7m7-7H3"
    />
  </svg>
);

export default SvgArrowRight;
