import * as React from "react";

const SvgSize1M2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M110.3 167.4V108.4c0-.1-.1-.2-.1-.4 0-.1-.1-.3-.1-.4 0-.1-.1-.2-.2-.4-.1-.1-.1-.2-.2-.3l-.3-.3-.2-.2-.1-.1-.3-.3c-.1-.1-.2-.2-.3-.2-.1-.1-.2-.1-.3-.2-.1-.1-.2-.1-.3-.2-.1-.1-.2-.1-.4-.1-.1 0-.2-.1-.4-.1H42.5V46.7h-8V172c0 .1.1.2.1.4 0 .1.1.3.1.4 0 .1.1.2.2.4.1.1.1.2.2.3l.3.3.2.2.1.1c.1.1.3.2.4.3l.2.2c.2.1.3.2.5.3.1 0 .1.1.2.1.5.2 1 .3 1.6.3h135.6v-8h-63.9zM96 113.1l-53.5 49.2v-49.2H96zm-47.2 54.3 53.5-49.2v49.2H48.8z" />
  </svg>
);

export default SvgSize1M2;
