import * as React from "react";

const SvgSize3M2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M178 46.5v-.4c0-.1-.1-.2-.1-.4 0-.1-.1-.3-.1-.4 0-.1-.1-.2-.2-.4-.1-.1-.1-.2-.2-.3l-.3-.3c-.1-.1-.1-.2-.2-.2h-.1l-.3-.3c-.1-.1-.2-.2-.3-.2-.1-.1-.2-.1-.3-.2-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.3-.1-.1 0-.3-.1-.4-.1H38.5c-2.2 0-4 1.8-4 4v124.6c0 2.2 1.8 4 4 4H174c2.2 0 4-1.8 4-4V46.7c.1-.1 0-.1 0-.2zm-14.1 4.2L42.5 163.6V50.7h121.4zM50.2 167.4 170.1 55.9v111.4H50.2z" />
  </svg>
);

export default SvgSize3M2;
