import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import SummaryText from "./Summary";
import * as icons from "../icons";

const formId = process.env.REACT_APP_HUBSPOT_FORMID;
const portalId = process.env.REACT_APP_HUBSPOT_PORTALID;
const region = process.env.REACT_APP_HUBSPOT_REGION;

// hubspot callbacks need jquery, okay...
window.jQuery =
  window.jQuery ||
  (() => ({
    change: () => {},
    trigger: () => {},
  }));

function Form({ children, onClose, ...props }) {
  const { error } = useHubspotForm({
    region: region,
    portalId: portalId,
    formId: formId,
    locale: "de",
    target: "#my-hubspot-form",
    onFormReady: function () {
      const frame = document.querySelector(".hs-form-iframe");
      if (frame && frame.contentWindow) {
        const win = frame.contentWindow;
        const el = win.document.querySelector(
          'textarea[name="bemerkungen_solarrechner"]'
        );
        if (el) {
          const summary = document.querySelector("#summary-text").innerText;

          // react 16+
          var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLTextAreaElement.prototype,
            "value"
          ).set;
          nativeInputValueSetter.call(el, summary);

          var ev = new Event("input", { bubbles: true });
          el.dispatchEvent(ev);
        }
      }
    },
  });
  return (
    <>
      {error ? (
        <span className="text-red-500">Error</span>
      ) : (
        <div className="text-center relative">
          <h2 className="font-bold py-2">Beratungstermin vereinbaren</h2>
          <button className="absolute right-2 top-2" onClick={onClose}>
            <icons.Close className="w-6 h-6" />
          </button>
          <div id="my-hubspot-form" className="p-4" />
        </div>
      )}
      <div id="summary-text" className="hidden">
        <SummaryText />
      </div>
    </>
  );
}

export default Form;
