import * as React from "react";

const SvgAutopilot = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M106.3 153.7c-26.2 0-47.4-21.3-47.4-47.4 0-26.2 21.3-47.4 47.4-47.4 26.2 0 47.4 21.3 47.4 47.4 0 26.2-21.2 47.4-47.4 47.4zm0-87.8C84 65.9 65.9 84 65.9 106.3s18.1 40.4 40.4 40.4 40.4-18.1 40.4-40.4-18.1-40.4-40.4-40.4z" />
    <path d="M81.9 108.9c-2.7 0-5.2-1.1-7-3.2-2-2.3-2.8-5.5-1.9-8.5 4.1-15 17.8-25.5 33.3-25.5 15.6 0 29.3 10.5 33.3 25.5.8 3 .1 6.1-1.9 8.5-2 2.3-5.1 3.5-8.1 3.1l-17.8-2.2c-2.9-.4-7.9-.4-10.8 0l-17.8 2.2c-.5.1-.9.1-1.3.1zm24.4-30.1c-12.4 0-23.3 8.4-26.6 20.3-.3 1 .2 1.8.5 2 .2.3.9.9 2 .8l17.8-2.2c3.5-.4 9-.4 12.5 0l17.8 2.2c1.1.1 1.7-.5 2-.8.2-.3.8-1 .5-2-3.2-12-14.1-20.3-26.5-20.3zM157.4 127.6c6-14.3 5.6-30.6-.9-44.7l6.3-3c7.4 15.8 7.7 34.2 1 50.3l-6.4-2.6z" />
    <path d="M169.3 131.9c7.2-17.2 6.8-36.8-1.1-53.7l6.3-3c8.7 18.7 9.1 40.3 1.2 59.4l-6.4-2.7zM96.6 137.7c-1.3 0-2.5-.3-3.7-.9-4.8-2.4-8.9-6.1-11.9-10.6-1.7-2.6-1.8-5.8-.3-8.5s4.3-4.4 7.4-4.4h8.5c4.6 0 8.4 3.8 8.4 8.4v7.7c0 2.9-1.5 5.6-4 7.1-1.4.8-2.9 1.2-4.4 1.2zm-8.5-17.4c-.7 0-1.1.5-1.2.7-.1.3-.3.8 0 1.4 2.3 3.5 5.4 6.3 9.1 8.2.5.3 1 .1 1.2-.1.2-.1.7-.5.7-1.2v-7.7c0-.7-.6-1.4-1.4-1.4h-8.4zM116.2 137.7c-1.5 0-3-.4-4.4-1.2-2.5-1.5-4-4.2-4-7.1v-7.7c0-4.6 3.8-8.4 8.4-8.4h8.5c3.1 0 5.9 1.7 7.4 4.4s1.3 5.9-.3 8.5c-2.9 4.5-7 8.2-11.9 10.6-1.1.6-2.4.9-3.7.9zm.1-17.4c-.7 0-1.4.6-1.4 1.4v7.7c0 .7.5 1.1.7 1.2.2.1.7.3 1.2.1 3.7-1.9 6.9-4.7 9.1-8.2.4-.6.2-1.1 0-1.4-.1-.3-.5-.7-1.2-.7h-8.4zM55.2 87c-6 14.3-5.6 30.6.9 44.7l-6.3 3c-7.4-15.8-7.7-34.2-1-50.3l6.4 2.6z" />
    <path d="M43.3 82.7c-7.2 17.2-6.8 36.8 1.1 53.7l-6.3 3C29.4 120.7 29 99.1 36.9 80l6.4 2.7z" />
  </svg>
);

export default SvgAutopilot;
