import * as React from "react";

const SvgWall = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 4.5H3a.75.75 0 0 0-.75.75v13.5a.75.75 0 0 0 .75.75h18a.75.75 0 0 0 .75-.75V5.25A.75.75 0 0 0 21 4.5Zm-12.75 9v-3h7.5v3h-7.5Zm-4.5 0v-3h3v3h-3Zm13.5-3h3v3h-3v-3Zm3-1.5h-7.5V6h7.5v3Zm-9-3v3h-7.5V6h7.5Zm-7.5 9h7.5v3h-7.5v-3Zm9 3v-3h7.5v3h-7.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWall;
