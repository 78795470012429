import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import './App.css';
import Scope from './screens/Scope';
import NotFound from './screens/NotFound';
import Devices from './screens/Devices';
import SolarModules from './screens/SolarModules';
import Battery from './screens/Battery';
import Battery2 from './screens/Battery2';
import Montage from './screens/Montage';
import Results from './screens/Results';
import Balcony from './screens/Balcony';
import VillaInstallation from './screens/VillaInstallation';
import { StateContext, DispatchContext } from './Context';


const initConsumerState = (names) => ({
  ...names.reduce(
    (result, name) => ({...result, [name]: ''}),
    {}
  ),
  extra: []
});

function reducer(state, action) {
  switch (action.type) {
    case 'set-scope': {
      if (action.scope === 'campervan') {
        return {
          ...state,
          scope: action.scope,
          devices: initConsumerState([
            'cooler', 'light', 'smartphone', 'pump', 'heater', 'laptop', 'coffee', 'stove', 'ebike'
          ]),
          battery: {
            haveAlreadyYes: false,
            haveAlreadyNo: false,
            type: undefined,
          },
          villaInstallation: undefined,
          balcony: undefined,
        };
      }
      if (action.scope === 'villa') {
        return {
          ...state,
          scope: action.scope,
          solarmodule: { flex: false, starr: true, faltbar: false },
          devices: initConsumerState([
            'cooler2', 'freezer', 'light2', 'light3', 'coffee2', 'pump2', 'tv', 'smartphone2', 'laptop2'
          ]),
          battery: {
            haveAlreadyYes: false,
            haveAlreadyNo: true,
            type: undefined,
          },
          villaInstallation: {
            option1: '',
            option2: '',
          },
          balcony: undefined,
        };
      }
      if (action.scope === 'schiffe') {
        return {
          ...state,
          scope: action.scope,
          devices: initConsumerState([
            'cooler', 'freezer', 'pump', 'autopilot', 'navi', 'radio', 'light2', 'light4', 'smartphone', 'laptop', 'coffee',
          ]),
          battery: {
            haveAlreadyYes: false,
            haveAlreadyNo: false,
            type: undefined,
          },
          villaInstallation: undefined,
          balcony: undefined,
        };
      }
      if (action.scope === 'plugin') {
        return {
          ...state,
          scope: action.scope,
          devices: undefined,
          battery: undefined,
          villaInstallation: undefined,
          balcony: undefined,
        };
      }
      return { ...state, scope: action.scope };
    }
    case 'set-devices': {
      return { ...state, devices: action.devices };
    }
    case 'set-solarmodule': {
      return { ...state, solarmodule: action.solarmodule };
    }
    case 'set-battery': {
      return { ...state, battery: action.battery };
    }
    case 'set-montage': {
      return { ...state, montage: action.montage };
    }
    case 'set-set': {
      return { ...state, set: action.set };
    }
    case 'set-villa-installation': {
      return { ...state, villaInstallation: action.value };
    }
    case 'set-inverter': {
      return { ...state, inverter: action.inverter };
    }
    case 'set-materials': {
      return { ...state, materials: action.materials };
    }
    case 'set-contacts': {
      return { ...state, contacts: action.contacts };
    }
    case 'set-balcony': {
      return { ...state, balcony: action.size };
    }
    default: {
      throw new Error();
    }
  }
}

const initialState = {
  current: 'initial',
  scope: undefined,
  devices: {
    extra: []
  },
  solarmodule: {
    flex: false,
    starr: false,
    faltbar: false,
  },
  battery: {
    haveAlreadyYes: false,
    haveAlreadyNo: false,
    type: undefined,
  },
  montage: undefined,
  set: undefined,
  inverter: undefined,
  materials: undefined,
  contacts: {
    titel: '',
    firstname: '',
    lastname: '',
    email: '',
    location: '',
    zip: '',
    address: '',
  },
  balcony: undefined,
};

const camperPath = [
  { component: Devices, route: 'devices', progress: 1 + 1/5, mobileProgress: 1/6 },
  { component: SolarModules, route: 'solarmodule', progress: 1 + 2/5, mobileProgress: 2/6 },
  { component: Battery, route: 'battery', progress: 1 + 3/5, mobileProgress: 3/6 },
  { component: Battery2, route: 'battery2', progress: 1 + 3/5, mobileProgress: 4/6 },
  { component: Montage, route: 'montage', progress: 1 + 4/5, mobileProgress: 5/6 },
  { component: Results, route: 'results', progress: 2, mobileProgress: 6/6 },
];

const villaPath = [
  { component: Devices, route: 'devices', progress: 1 + 1/5, mobileProgress: 1/5 },
  { component: VillaInstallation, route: 'installation', progress: 1 + 2/5, mobileProgress: 2/5 },
  { component: Battery2, route: 'battery', progress: 1 + 3/5, mobileProgress: 3/5 },
  { component: Montage, route: 'montage', progress: 1 + 4/5, mobileProgress: 4/5 },
  { component: Results, route: 'results', progress: 2, mobileProgress: 6/6 },
];

const shipPath = [
  { component: Devices, route: 'devices', progress: 1 + 1/5, mobileProgress: 1/6 },
  { component: SolarModules, route: 'solarmodule', progress: 1 + 2/5, mobileProgress: 2/6 },
  { component: Battery, route: 'battery', progress: 1 + 3/5, mobileProgress: 3/6 },
  { component: Battery2, route: 'battery2', progress: 1 + 3/5, mobileProgress: 4/6 },
  { component: Montage, route: 'montage', progress: 1 + 4/5, mobileProgress: 5/6 },
  { component: Results, route: 'results', progress: 2, mobileProgress: 6/6 },
];

const pluginPath = [
  { component: SolarModules, route: 'solarmodule', progress: 1 + 1/4, mobileProgress: 1/4 },
  { component: Balcony, route: 'flaeche', progress: 1 + 2/4, mobileProgress: 2/4 },
  { component: Montage, route: 'montage', progress: 1 + 3/4, mobileProgress: 3/4 },
  { component: Results, route: 'results', progress: 2, mobileProgress: 4/4 },
];

function ScopeRoute({ routes, name }) {
  const history = useHistory();
  const goNext = path => {
    history.push(path);
    window.scrollTo(0, 0);
  }
  const { scope } = React.useContext(StateContext);
  if (scope !== name) {
    return <Redirect to="/" />
  }
  return (
    <Switch>
      <Route exact path={`/${name}`}>
        <Redirect to={`/${name}/${routes[0].route}`} />
      </Route>
      {routes.map(({ component: Component, route, progress, mobileProgress }, index) => (
        <Route
          exact
          path={`/${name}/${route}`}
          key={index}
        >
          <Component
            next={() => index !== routes.length - 1 ? goNext(`/${name}/${routes[index+1].route}`) : null}
            back={() => index !== 0 ? goNext(`/${name}/${routes[index-1].route}`) : goNext("/")}
            progress={progress}
            mobileProgress={mobileProgress}
          />
        </Route>
      ))}
      <Route>
        <NotFound
          next={() => goNext("/")}
        />
      </Route>
    </Switch>
  );
}

function AppContext({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const setScope = React.useCallback((scope) => dispatch({ type: 'set-scope', scope }), [dispatch]);
  const setDevices = React.useCallback((devices) => dispatch({ type: 'set-devices', devices }), [dispatch]);
  const setSolarModule = React.useCallback(solarmodule => dispatch({ type: 'set-solarmodule', solarmodule }), [dispatch]);
  const setBattery = React.useCallback(battery => dispatch({ type: 'set-battery', battery }), [dispatch]);
  const setMontage = React.useCallback(montage => dispatch({ type: 'set-montage', montage }), [dispatch]);
  const setSet = React.useCallback(set => dispatch({ type: 'set-set', set }), [dispatch]);
  const setVillaInstallation = React.useCallback((option1, option2) => dispatch({ type: 'set-villa-installation', value: { option1, option2 } }), [dispatch]);
  const setInverter = React.useCallback(inverter => dispatch({ type: 'set-inverter', inverter }), [dispatch]);
  const setMaterials = React.useCallback(materials => dispatch({ type: 'set-materials', materials }), [dispatch]);
  const setContacts = React.useCallback(contacts => dispatch({ type: 'set-contacts', contacts }), [dispatch]);
  const setBalcony = React.useCallback(size => dispatch({ type: 'set-balcony', size }), [dispatch]);

  const dispatches = {
    setScope,
    setDevices,
    setSolarModule,
    setBattery,
    setMontage,
    setSet,
    setVillaInstallation,
    setInverter,
    setMaterials,
    setContacts,
    setBalcony,
  };

  return (
    <DispatchContext.Provider value={dispatches}>
      <StateContext.Provider value={state}>
        { children }
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
}

function AppRouter() {
  const history = useHistory();
  const { scope } = React.useContext(StateContext);
  const goNext = path => {
    history.push(path);
    window.scrollTo(0, 0);
  }
  return (
    <Switch>
      <Route exact path="/">
        <Scope
          next={(type) => goNext(`/${type ? type : scope}`)}
          progress={0}
        />
      </Route>
      <Route path={`/campervan`}>
        <ScopeRoute routes={camperPath} name="campervan" />
      </Route>
      <Route path={`/villa`}>
        <ScopeRoute routes={villaPath} name="villa" />
      </Route>
      <Route path={`/schiffe`}>
        <ScopeRoute routes={shipPath} name="schiffe" />
      </Route>
      <Route path={`/plugin`}>
        <ScopeRoute routes={pluginPath} name="plugin" />
      </Route>
      <Route>
        <NotFound next={() => goNext("/")} />
      </Route>
    </Switch>
  );
}

export function App() {
  return (
    <AppContext>
      <Router>
        <HubspotProvider>
          <AppRouter />
        </HubspotProvider>
      </Router>
    </AppContext>
  );
}

export default App;
