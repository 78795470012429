import * as React from "react";

const SvgEbike = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.417 9.455h-.752L18.107 5.2a1.827 1.827 0 0 0-.672-.869A1.85 1.85 0 0 0 16.383 4H14v1.818h2.383l1.339 3.637h-4.41l-.33-.91H14V6.727H9.417v1.818h1.604l1.668 4.546h-.614c-.403-2.027-2.117-3.527-4.262-3.627C5.245 9.336 3 11.454 3 14c0 2.546 2.017 4.546 4.583 4.546 2.255 0 4.08-1.537 4.492-3.637h3.85c.403 2.027 2.117 3.527 4.262 3.627 2.567.118 4.813-1.99 4.813-4.545 0-2.537-2.017-4.536-4.583-4.536Zm-10.249 5.454a2.702 2.702 0 0 1-2.585 1.818c-1.54 0-2.75-1.2-2.75-2.727s1.21-2.727 2.75-2.727c1.22 0 2.219.754 2.585 1.818H7.583v1.818h2.585Zm5.757-1.818h-1.283l-.67-1.818h2.778a4.287 4.287 0 0 0-.825 1.818Zm4.492 3.636c-1.54 0-2.75-1.2-2.75-2.727 0-.845.375-1.573.962-2.073l.88 2.4 1.724-.618-.89-2.427c.019 0 .046-.01.074-.01 1.54 0 2.75 1.2 2.75 2.728 0 1.527-1.21 2.727-2.75 2.727Zm-7.334 4.546H9.417l5.5 2.727v-1.818h3.666l-5.5-2.727v1.818Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEbike;
