import * as React from "react";

export function useScrollPercentage() {
  const [percentage, setPercentage] = React.useState(1.0);
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollMaxY = window.scrollMaxY
        ? window.scrollMaxY
        : document.documentElement.scrollHeight -
          document.documentElement.clientHeight;
      if (scrollMaxY) {
        const value = window.scrollY / scrollMaxY;
        setPercentage(value);
      } else {
        setPercentage(1.0);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);
  return percentage;
}

export default useScrollPercentage;
