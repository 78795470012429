import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import * as imgIcons from "../img-icons";
import * as icons from "../icons";
import { ButtonIconWide, ButtonImgIconWide } from "../components/Button";
import { StateContext, DispatchContext } from "../Context";

export default function MontagePage(props) {
  const { scope, montage } = React.useContext(StateContext);
  const { setMontage } = React.useContext(DispatchContext);
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Montageart"
      canGoNext={montage !== undefined}
      {...props}
    >
      <BodyLeft>
        <BodyLeft.Title>
          Möchtest du die Solaranlage selber installieren oder installieren
          lassen?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Wählen deine gewünschte Montageart aus.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight>
        <MontageBlock
          scope={scope}
          montage={montage}
          setMontage={(data) => {
            setMontage(data);
            props.next();
          }}
          {...props}
        />
      </BodyRight>
    </BaseScopeRow>
  );
}

function MontageButton({ icon, value, montage, setMontage, children }) {
  return (
    <ButtonImgIconWide
      icon={icon}
      active={montage === value}
      onClick={() => setMontage(value)}
    >
      {children}
    </ButtonImgIconWide>
  );
}

function MontageButton2({ icon, value, montage, setMontage, children }) {
  return (
    <ButtonIconWide
      icon={icon}
      active={montage === value}
      onClick={() => setMontage(value)}
    >
      {children}
    </ButtonIconWide>
  );
}

function MontageBlock(props) {
  const { scope } = props;
  return (
    <div className="flex flex-col items-center">
      <MontageButton icon={imgIcons.Gears} value="self" {...props}>
        Selbstmontage – Einbauanleitung wird beigelegt
      </MontageButton>
      {scope === 'campervan' && (
        <MontageButton icon={imgIcons.Villa} value="partner-at-oetwil" {...props}>
          Montage in unserer Werkstatt in Oetwil am See
        </MontageButton>
      )}
      {scope === 'schiffe' && (
        <MontageButton2 icon={icons.Boot} value="partner-at-port" {...props}>
          Montage bei Ihnen im Hafen durch unseren Montagepartner
        </MontageButton2>
      )}
      {(scope === 'villa' || scope === 'plugin') && (
        <MontageButton icon={imgIcons.House2} value="partner-at-home" {...props}>
          Montage bei Ihnen zu Hause durch unseren Montagepartner
        </MontageButton>
      )}
      <MontageButton icon={imgIcons.Factory} value="self-engineer" {...props}>
        Montage bei dem Monteur deines Vertrauens
      </MontageButton>
    </div>
  );
}
