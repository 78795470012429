import * as React from "react";

const SvgPlugSingle = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.667 19.333V24m2.667-4.667V24M10 4v6m8-6v6M7.334 10h13.333v4h-1.334v2.667a2.667 2.667 0 0 1-2.666 2.666h-5.334a2.667 2.667 0 0 1-2.666-2.666V14H7.334v-4Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgPlugSingle;
