import * as React from "react";

const SvgRadio = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M160.1 70.4H71.6L113.9 36c1.7-1.4 2-3.9.6-5.6-1.4-1.7-3.9-2-5.6-.6l-50 40.6h-6.4c-7.4 0-13.4 6-13.4 13.4v71.1c0 7.4 6 13.4 13.4 13.4H160c7.4 0 13.4-6 13.4-13.4v-71c.1-7.4-5.9-13.5-13.3-13.5zm5.4 84.5c0 3-2.4 5.4-5.4 5.4H52.5c-3 0-5.4-2.4-5.4-5.4v-71c0-3 2.4-5.4 5.4-5.4H160c3 0 5.4 2.4 5.4 5.4v71z" />
    <path d="M80.3 95.7C68 95.7 58 105.7 58 118s10 22.3 22.3 22.3 22.3-10 22.3-22.3-10-22.3-22.3-22.3zm0 36.6c-7.9 0-14.3-6.4-14.3-14.3s6.4-14.3 14.3-14.3 14.3 6.4 14.3 14.3-6.4 14.3-14.3 14.3z" />
    <circle cx={80.3} cy={118} r={5.9} />
    <path d="M114.2 101.2h36.4v8h-36.4zM114.2 114h36.4v8h-36.4zM114.2 126.8h36.4v8h-36.4z" />
  </svg>
);

export default SvgRadio;
