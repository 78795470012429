import * as React from "react";

const SvgHeater = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={6.75}
      y={2.75}
      width={18.5}
      height={22.5}
      rx={2.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <rect x={9} y={5} width={1.5} height={18} rx={0.75} fill="currentColor" />
    <rect x={13} y={5} width={1.5} height={18} rx={0.75} fill="currentColor" />
    <rect x={17} y={5} width={1.5} height={18} rx={0.75} fill="currentColor" />
    <rect x={21} y={5} width={1.5} height={18} rx={0.75} fill="currentColor" />
    <path
      d="M3.75 7.5a.75.75 0 0 1 .75-.75h.75v1.5H4.5a.75.75 0 0 1-.75-.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <mask id="heater_svg__a" fill="#fff">
      <path d="M2 7.5a.5.5 0 0 1 .5-.5H4v1H2.5a.5.5 0 0 1-.5-.5Z" />
    </mask>
    <path
      d="M4 7h1.5V5.5H4V7Zm0 1v1.5h1.5V8H4Zm-1.5.5H4v-3H2.5v3Zm0-1.5v1h3V7h-3ZM4 6.5H2.5v3H4v-3Zm-1.5 0a1 1 0 0 1 1 1h-3a2 2 0 0 0 2 2v-3Zm0-1a2 2 0 0 0-2 2h3a1 1 0 0 1-1 1v-3Z"
      fill="currentColor"
      mask="url(#heater_svg__a)"
    />
    <path
      d="M3.75 20.5a.75.75 0 0 1 .75-.75h.75v1.5H4.5a.75.75 0 0 1-.75-.75Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <mask id="heater_svg__b" fill="#fff">
      <path d="M2 20.5a.5.5 0 0 1 .5-.5H4v1H2.5a.5.5 0 0 1-.5-.5Z" />
    </mask>
    <path
      d="M4 20h1.5v-1.5H4V20Zm0 1v1.5h1.5V21H4Zm-1.5.5H4v-3H2.5v3Zm0-1.5v1h3v-1h-3Zm1.5-.5H2.5v3H4v-3Zm-1.5 0a1 1 0 0 1 1 1h-3a2 2 0 0 0 2 2v-3Zm0-1a2 2 0 0 0-2 2h3a1 1 0 0 1-1 1v-3Z"
      fill="currentColor"
      mask="url(#heater_svg__b)"
    />
  </svg>
);

export default SvgHeater;
