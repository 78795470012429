import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight, toPrice } from "./Base";
import Spinner from "../components/Spinner";
import { StateContext } from "../Context";
import { getSets2, sendNotifyOrder, getCart, getReport } from "../api/api";
import ContactsModal from "../components/ContactsModal";
import TerminModal from "../components/TerminModal";
import SummaryModal from "../components/SummaryModal";
import * as icons from "../icons";

function getItemListPrice(items) {
  return items
    .map((it) => it.price_tax_incl * it.installation_material_quantiy)
    .reduce((r, v) => r + v, 0);
}

function useSetsHook() {
  const { scope, devices, solarmodule, battery, villaInstallation, balcony } =
    React.useContext(StateContext);
  const [loading, setLoading] = React.useState("initial");
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    setLoading("pending");
    getSets2({ scope, devices, solarmodule, battery, villaInstallation, balcony })
      .then((data) => {
        setData(data.products);
        setLoading("ready");
      })
      .catch((err) => {
        setLoading("error");
      });
  }, [
    scope,
    devices,
    solarmodule,
    battery,
    villaInstallation,
    setLoading,
    setData,
    balcony,
  ]);
  return [data, loading];
}

function Box({ checked, children, ...props }) {
  return (
    <div
      className={`w-full
      flex flex-row justify-start items-center
      border-t
      text-midnight border-midnight
      `}
      {...props}
    >
      {children}
    </div>
  );
}

function useSendOffer({ set }) {
  const { scope, devices, solarmodule, battery, montage, villaInstallation } =
    React.useContext(StateContext);
  const [loading, setLoading] = React.useState("initial");
  const callback = React.useCallback(
    (contacts) => {
      setLoading("pending");
      getReport({
        scope,
        devices,
        solarmodule,
        battery,
        montage,
        villaInstallation,
        set,
        inverter: set.inverter,
        materials: set.materials,
        contacts: {
          titel: contacts.titel,
          firstname: contacts.firstname,
          lastname: contacts.lastname,
          email: contacts.email,
          location: "",
          zip: "",
          address: "",
          phone: contacts.phone,
        },
      })
        .then((data) => {
          setLoading("ready");
        })
        .catch((err) => {
          console.log(err);
          setLoading("error");
        });
    },
    [set, scope, devices, solarmodule, battery, montage, villaInstallation]
  );
  return [callback, loading];
}

function useOrderHook({ set }) {
  const { scope, villaInstallation, devices, solarmodule, battery, montage } =
    React.useContext(StateContext);
  const [loading, setLoading] = React.useState("initial");
  const orderCallback = React.useCallback(() => {
    setLoading("pending");
    sendNotifyOrder({
      scope,
      devices,
      solarmodule,
      battery,
      montage,
      villaInstallation,
    })
      .then(() => {
        return getCart({
          set,
          inverter: set.inverter,
          materials: set.materials,
        });
      })
      .then((data) => {
        setLoading("ready");
        window.location = data.url;
      })
      .catch((err) => {
        console.log(err);
        setLoading("error");
      });
  }, [
    scope,
    set,
    villaInstallation,
    devices,
    solarmodule,
    battery,
    montage,
    setLoading,
  ]);
  return [orderCallback, loading];
}

function ResultSet({ index, item }) {
  const { name, cover_img: image, quantity } = item;
  const match = name.match(/Solarset (\d{1,4}W).*/);
  const wattName = match ? match[1] : '';
  const { montage } = React.useContext(StateContext);
  const freeDelivery = montage === 'self' || montage === 'self-engineer' || montage === 'partner-at-home';
  const available = quantity > 0;
  const [expanded, setExpanded] = React.useState(false);
  const [orderNow, loadingOrder] = useOrderHook({ set: item });
  const [sendOffer, loadingOffer] = useSendOffer({ set: item });
  const [showContacts, setShowCotacts] = React.useState(false);
  const [showTermin, setShowTermin] = React.useState(false);
  const { inverter, materials } = item;
  const setPrice = item.price_tax_incl;
  const inverterPrice = inverter ? inverter.price_tax_incl : 0;
  const materialsPrice =
    materials.length !== 0 ? getItemListPrice(materials) : 0;
  const totalPrice = setPrice + inverterPrice + materialsPrice;
  const withoutMaterials = name.match(/ohne Montagematerial/);
  if (loadingOrder === "pending") {
    return <Spinner />;
  }
  if (loadingOrder === "error") {
    return <>Ein unerwarteter Fehler ist aufgetreten</>;
  }
  return (
    <Box>
      <ContactsModal
        open={showContacts}
        setOpen={setShowCotacts}
        onSubmit={(data) => sendOffer(data)}
        loading={loadingOffer}
      />
      <TerminModal open={showTermin} setOpen={setShowTermin} />
      <div className="flex flex-col w-full relative my-4">
        <div className="absolute top-0 right-0">
          <icons.ChevronDown
            className={`
              h-8 w-8 m-2
              transition-transform duration-300 ${expanded ? 'rotate-180' : ''}
            `}
            stroke-width={0.5}
            onClick={() => setExpanded(!expanded)}
          />
        </div>
        {!expanded ? (
          <>
            <div className="text-lg font-bold mt-8">Variante {index}: Solaranlage {wattName}</div>
            <div className="flex flex-row items-stretch md:justify-between w-full">
              <ul className="mr-4 flex flex-col w-1/2 sm:w-auto justify-center">
                <li className="text-lg mb-4">{name}</li>
                <li className="">CHF {toPrice(totalPrice)} {!!withoutMaterials ? '' : 'inkl. Montagematerial'}</li>
                {available ? (
                  <li className="">ab Lager verfügbar</li>
                ) : (
                  <li className="">Momentan nicht auf Lager</li>
                )}
                <li
                  className="my-4 underline hover:no-underline cursor-pointer"
                  onClick={() => setExpanded(true)}
                >
                  mehr anzeigen
                </li>
              </ul>
              <div className="w-1/2 sm:w-auto mx-2">
                <img
                  src={image}
                  alt={name}
                  className="block max-w-[120px] sm:max-w-[200px] object-contain hover:cursor-pointer mix-blend-multiply mx-8"
                  onClick={() => setExpanded(true)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-lg font-bold mt-8">Variante {index}: Solaranlage {wattName}</div>
            <Item item={item} />
            {inverter ? (
              <>
                <div className="text-lg font-bold mt-8">Wechselrichter</div>
                <Item item={inverter} />
              </>
            ) : null}
            {materials && materials.length ? (
              <>
                <div className="text-lg font-bold mt-8">Montagematerial</div>
                {materials.map((item, i) => (
                  <Item
                    item={item}
                    item_quantity={item.installation_material_quantiy}
                    key={i}
                  />
                ))}
              </>
            ) : null}
            <div className="w-full sm:w-5/6">
              <div className="text-lg font-bold mt-8">Zusammenfassung</div>
              <div className="flex flex-row">
                <span>Solaranlage</span>
                <div className="flex-grow" />
                <span>CHF {toPrice(setPrice)}</span>
              </div>
              {inverter ? (
                <div className="flex flex-row">
                  <span>Wechselrichter</span>
                  <div className="flex-grow" />
                  <span>CHF {toPrice(inverterPrice)}</span>
                </div>
              ) : null}
              {materials && materials.length ? (
                <div className="flex flex-row">
                  <span>Montagematerial</span>
                  <div className="flex-grow" />
                  <span>CHF {toPrice(materialsPrice)}</span>
                </div>
              ) : null}
              {freeDelivery ? (
                <div className="flex flex-row">
                  <span>Versandkosten</span>
                  <div className="flex-grow" />
                  <span>kostenlos</span>
                </div>
              ) : null}
              <div className="flex flex-row mt-8">
                <span>{item.name}</span>
                <div className="flex-grow" />
              </div>
              {inverter ? (
                <div className="flex flex-row">
                  <span>{inverter.name}</span>
                  <div className="flex-grow" />
                </div>
              ) : null}
              {materials && materials.length ? (
                <>
                  {materials.map(m => (
                    <div className="flex flex-row">
                      <span>{m.name}</span>
                      <div className="flex-grow" />
                    </div>
                  ))}
                </>
              ) : null}
              <div className="flex flex-row font-bold border-b border-midnight my-8">
                <span>Total</span>
                <div className="flex-grow" />
                <span>CHF {toPrice(totalPrice)}</span>
              </div>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-row items-center mr-4" onClick={() => setShowTermin(true)}>
                <icons.Beratung className="h-8 w-8" />
                <span className="ml-2 underline hover:no-underline cursor-pointer">Beratungstermin vereinbaren</span>
              </div>
              <div className="flex flex-row items-center" onClick={() => setShowCotacts(true)}>
                <icons.Offerte className="h-8 w-8" />
                <span className="ml-2 underline hover:no-underline cursor-pointer">Offerte als PDF bestellen</span>
              </div>
            </div>
            <div className="py-4 mb-8">
              <FlatButton onClick={() => orderNow()}>
                Im Onlineshop bestellen
              </FlatButton>
            </div>
            <div
              className="my-4 underline hover:no-underline cursor-pointer"
              onClick={() => setExpanded(false)}
            >
              weniger anzeigen
            </div>
          </>
        )}
      </div>
    </Box>
  );
}

function Item({ item, item_quantity }) {
  const { name, url, cover_img: image, price_tax_incl: price, quantity } = item;
  const available = quantity > 0;
  return (
    <div className="flex flex-row items-stretch md:justify-between w-full py-2">
      <ul className="mr-4 flex flex-col w-1/2 sm:w-auto justify-center">
        <li className="text-lg mb-4">{name}</li>
        {item_quantity !== undefined && <li>Menge: {item_quantity}</li>}
        <li className="">CHF {toPrice(price)}</li>
        {available ? (
          <li className="">ab Lager verfügbar</li>
        ) : (
          <li className="">Momentan nicht auf Lager</li>
        )}
        <li className="mt-4 underline hover:no-underline cursor-pointer">
          <a href={url} target="_blank" rel="noreferrer" className="outline-none">
            Detailansicht im Onlineshop
          </a>
        </li>
      </ul>
      <a href={url} target="_blank" rel="noreferrer" className="outline-none w-1/2 sm:w-auto mx-2 hover:cursor-pointer">
        <img
          src={image}
          alt={name}
          className="block max-w-[120px] sm:max-w-[200px] object-contain mix-blend-multiply mx-8"
        />
      </a>
    </div>
  );
}

function FlatButton({ children, ...props }) {
  return (
    <div
      className="inline text-midnight
      bg-sun-yellow-light hover:bg-sun-yellow
      py-2 px-4
      uppercase
      shadow-sm shadow-gray-500
      cursor-pointer"
      {...props}
    >
      {children}
    </div>
  );
}

export default function Results(props) {
  const [data, loading] = useSetsHook();
  const [showTermin, setShowTermin] = React.useState(false);
  const [showSummary, setShowSummary] = React.useState(false);
  return (
    <BaseScopeRow
      scopeTitle="Unsere Vorschläge: Solaranlagen"
      canGoNext={false}
      {...props}
    >
      {loading === 'pending' ? (
        <div className="flex-auto sm:flex-1 relative">
          <div className="container mx-auto p-4">
            <BodyLeft.Title>
              Bitte warten
            </BodyLeft.Title>
          </div>
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner />
          </div>
        </div>
      ) : (
        <div className="relative w-full flex flex-row flex-wrap flex-1">
          <BodyLeft className="">
            {loading === "error" ? (
              <BodyLeft.Title>
                Ein unerwarteter Fehler ist aufgetreten
              </BodyLeft.Title>
            ) : null}
            {loading === "ready" && data.length === 0 ? (
              <>
                <BodyLeft.Title>Kein Ergebnis</BodyLeft.Title>
                <TerminModal open={showTermin} setOpen={setShowTermin} />
                <FlatButton onClick={() => setShowTermin(true)}>
                  Beratungstermin vereinbaren
                </FlatButton>
              </>
            ) : null}
            {loading === "ready" && data.length !== 0 ? (
              <>
                <BodyLeft.Title>Unsere Vorschläge: Solaranlagen</BodyLeft.Title>
                <BodyLeft.Subtitle>
                  <SummaryModal open={showSummary} setOpen={setShowSummary} />
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setShowSummary(true)}
                  >
                    Gemäss deinen Angaben
                  </span>{" "}
                  können wir dir folgende Solaranlagen anbieten. Alle Varianten
                  reichen für deine Verbraucher bei schönem Wetter aus.
                </BodyLeft.Subtitle>
                <BodyLeft.Subtitle>Wähle eine Anlage aus.</BodyLeft.Subtitle>
              </>
            ) : null}
          </BodyLeft>
          <BodyRight>
            <div className="flex flex-col">
              {loading === "ready" && data.length !== 0 ? (
                <>
                  {data.map((item, i) => (
                    <ResultSet key={i} item={item} index={i + 1} />
                  ))}
                </>
              ) : null}
            </div>
          </BodyRight>
        </div>
      )}
    </BaseScopeRow>
  );
}
