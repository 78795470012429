import * as React from "react";
import ModalBase from "./ModalBase";
import Form from "./TerminForm";

export function Modal({ open, setOpen }) {
  return (
    <ModalBase
      open={open}
      setOpen={setOpen}
      hideSubmit={true}
      hideClose={true}
      canSubmit={false}
      onSubmit={() => {}}
    >
      {open ? <Form onClose={() => setOpen(false)} /> : null}
    </ModalBase>
  );
}

export default Modal;
