import * as React from "react";

const SvgRoofStone = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m22.485 5.067-9.187 9.157M12.766 16.713v.367l.29.225.312.243v.757H1.75v-.757l.313-.243.29-.225V15.13l-.22-.22-.383-.383v-.479h11.652l.02.426-.436.436-.22.22v1.582Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M1.444 13.787 10.972 5h11.713v1.874l-.67.602V8.48l.47.469-.067 1.205-8.764 8.43"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <circle cx={4.507} cy={16.177} r={0.708} fill="currentColor" />
    <circle cx={6.564} cy={16.177} r={0.708} fill="currentColor" />
    <circle cx={8.621} cy={16.177} r={0.708} fill="currentColor" />
    <circle cx={10.678} cy={16.177} r={0.708} fill="currentColor" />
  </svg>
);

export default SvgRoofStone;
