import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import Battery1 from "../images/battery1.jpeg";
import Battery2 from "../images/battery2.jpeg";
import { StateContext, DispatchContext } from "../Context";
import { getSumWatt } from "../api/api";
import { ButtonBase } from "../components/Button";

const roundToNext10 = (value) => Math.ceil(value / 10) * 10;

export default function BatteryPage(props) {
  const { battery, scope, devices } = React.useContext(StateContext);
  const { setBattery } = React.useContext(DispatchContext);
  const valid = !!battery.type;
  const wattHours = getSumWatt(devices);
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Batterie"
      canGoNext={valid}
      {...props}
    >
      <BodyLeft>
        <BodyLeft.Title>
          Möchtest du eine Lithiumbatterie oder eine AGM-Batterie haben?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          {battery.haveAlreadyYes ? (
            <>Hast du eine Lithiumbatterie oder eine AGM-Batterie?</>
          ) : (
            <>Wähle den gewünschten Batterietyp aus.</>
          )}
        </BodyLeft.Subtitle>
        {battery.haveAlreadyYes ? (
          <BodyLeft.Subtitle>
            Hinweis: {scope === "campervan" ? <>Normalerweise ist eine AGM-Batterie im Camper verbaut. </> : null}
            Für deine Verbraucher benötigen du eine {roundToNext10(wattHours / 14)} Ah-Batterie (AGM) oder eine  {roundToNext10(wattHours / 28)} Ah-Lithiumbatterie.
          </BodyLeft.Subtitle>
        ) : null}
        {battery.haveAlreadyNo ? (
          <BodyLeft.Subtitle>
            Hinweis: Lithiumbatterien haben eine 6 mal längere Lebensdauer.
          </BodyLeft.Subtitle>
        ) : null}
      </BodyLeft>
      <BodyRight>
        <BatterySelect
          battery={battery}
          setBattery={(data) => {
            setBattery(data);
            props.next();
          }}
        />
      </BodyRight>
    </BaseScopeRow>
  );
}

function BatterySelect({ battery, setBattery }) {
  return (
    <div className="flex flex-row">
      <Button
        image={Battery2}
        name="Lithiumbatterie"
        value={battery.type === "Lithium"}
        setValue={() =>
          setBattery({
            ...battery,
            type: battery.type !== "Lithium" ? "Lithium" : undefined,
          })
        }
        features={[
          "+ leicht und klein",
          "+ lange Lebenszeit (>3000 Zyklen)",
          "- Preis ist höher",
          "- braucht MPPT-Laderegler",
        ]}
      />
      <Button
        image={Battery1}
        name="AGM-Batterie"
        value={battery.type === "AGM"}
        setValue={() =>
          setBattery({
            ...battery,
            type: battery.type !== "AGM" ? "AGM" : undefined,
          })
        }
        features={[
          "+ günstiger",
          "+ auslaufsicher",
          "- 4x schwerer als Lithiumbatterie",
          "- kürzere Lebensdauer (>500 Zykeln)",
        ]}
      />
    </div>
  );
}

function Button({ image, name, value, setValue, features }) {
  return (
    <ButtonBase
      className={`
      flex flex-col items-center
      p-2 pb-4 m-2 sm:m-4`}
      onClick={() => setValue(!value)}
      active={value}
    >
      <img
        src={image}
        alt={name}
        className="block max-w-[120px] sm:max-w-[240px] object-contain mix-blend-multiply"
      />
      <ul className="text-center">
        <li className="my-2 text-lg">{name}</li>
        {features.map((name, i) => (
          <li key={i} className="text-sm">
            {name}
          </li>
        ))}
      </ul>
    </ButtonBase>
  );
}
