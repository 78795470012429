import { useHistory } from "react-router-dom";

export default function Progress({ stage, scope, width, height }) {
  const history = useHistory();
  const navigate = (path) => history.push(path);

  const primary = "#FAAF0F";
  const secondary = "#EBE6D2";
  const stage1 = stage >= 0 ? primary : secondary;
  const stage2 = stage >= 1 ? primary : secondary;
  const stage3 = stage >= 2 ? primary : secondary;

  const text1 = "Anwendungsbereich";
  const text2 = "Deine Angaben";
  const text3 = "Unsere Vorschläge";

  const fontSize = 12;

  const xpadding = 0;
  const ypadding = 5;
  const radius = (height - 2 * ypadding - fontSize) / 2;
  const distanceBetween = (width - 2 * xpadding - 6 * radius) / 2;

  const yLine = ypadding + radius;
  const yText = yLine + radius + fontSize;

  const cx1 = xpadding + radius;
  const cx2 = cx1 + radius + distanceBetween + radius;
  const cx3 = cx2 + radius + distanceBetween + radius;

  const bar1 =
    stage === 0 ? cx1 : stage >= 1 ? cx2 : cx1 + distanceBetween * stage;
  const bar2 =
    stage <= 1 ? cx2 : stage >= 2 ? cx3 : cx2 + distanceBetween * (stage - 1);

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      className="hidden md:block progress-bar w-full h-full"
    >
      <line
        x1={cx1}
        y1={yLine}
        x2={cx2}
        y2={yLine}
        style={{ stroke: secondary, strokeWidth: "2" }}
      />
      <line
        x1={cx1}
        y1={yLine}
        x2={bar1}
        y2={yLine}
        style={{ stroke: primary, strokeWidth: "2" }}
      />
      <line
        x1={cx2}
        y1={yLine}
        x2={cx3}
        y2={yLine}
        style={{ stroke: secondary, strokeWidth: "2" }}
      />
      <line
        x1={cx2}
        y1={yLine}
        x2={bar2}
        y2={yLine}
        style={{ stroke: primary, strokeWidth: "2" }}
      />
      <circle
        onClick={() => (stage >= 0 ? navigate(`/`) : null)}
        cx={cx1}
        cy={yLine}
        r={radius}
        fill={stage1}
        className="hover:cursor-pointer"
      />
      <circle
        onClick={() => (stage >= 1 ? navigate(`/${scope}`) : null)}
        cx={cx2}
        cy={yLine}
        r={radius}
        fill={stage2}
        className="hover:cursor-pointer"
      />
      <circle
        onClick={() => (stage >= 2 ? navigate(`/${scope}/results`) : null)}
        cx={cx3}
        cy={yLine}
        r={radius}
        fill={stage3}
        className="hover:cursor-pointer"
      />
      <text
        onClick={() => (stage >= 0 ? navigate(`/`) : null)}
        x={cx1 - radius}
        y={yText}
        textAnchor="start"
        fill={stage1}
        fontSize={fontSize + "px"}
        className="hover:underline hover:cursor-pointer"
      >
        {text1}
      </text>
      <text
        onClick={() => (stage >= 1 ? navigate(`/${scope}`) : null)}
        x={cx2}
        y={yText}
        textAnchor="middle"
        fill={stage2}
        fontSize={fontSize + "px"}
        className="hover:underline hover:cursor-pointer"
      >
        {text2}
      </text>
      <text
        onClick={() => (stage >= 2 ? navigate(`/${scope}/results`) : null)}
        x={cx3 + radius}
        y={yText}
        textAnchor="end"
        fill={stage3}
        fontSize={fontSize + "px"}
        className="hover:cursor-pointer"
      >
        {text3}
      </text>
    </svg>
  );
}
