import { consumers } from "../screens/Devices";

const API_URL = "/api";
const SUPPRESS_NOTIFY = process.env.REACT_APP_SUPPRESS_NOTIFY !== undefined;

export function getSumWatt(devices) {
  if (!devices) {
    return 0;
  }
  const { extra, ...prefilled } = devices;
  let prefilledSum = Object.keys(consumers)
    .filter((it) => prefilled[it] !== undefined && prefilled[it] !== "")
    .map((it) => parseFloat(prefilled[it]) * consumers[it].wattHours)
    .reduce((r, v) => r + v, 0);
  if (prefilled['heater'] !== undefined && prefilled['heater'] > 0 && prefilled['freezer'] !== undefined && prefilled['freezer'] > 0) {
    prefilledSum -= consumers.heater.wattHours; // 400
  }
  const extraSum = extra
    .filter((it) => it.hours !== "" && it.watt !== "")
    .map((it) => parseFloat(it.hours) * parseFloat(it.watt))
    .reduce((r, v) => r + v, 0);
  return prefilledSum + extraSum;
}

export function getSets({ devices, solarmodule, battery }) {
  const body = {
    watt_hours: getSumWatt(devices),
    battery: battery ? battery.haveAlreadyNo : undefined,
    battery_type: battery ? (battery.type || "Lithium") : undefined,
    panel_type: [],
  };
  if (solarmodule.flex) body.panel_type.push("flex");
  if (solarmodule.starr) body.panel_type.push("starr");
  if (solarmodule.faltbar) body.panel_type.push("faltbar");
  return fetch(`${API_URL}/sets`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

function needInverter(devices) {
  if (!devices) {
    return false;
  }
  const { extra, ...prefilled } = devices;
  const prefilledCount = Object.keys(consumers)
    .filter((it) => prefilled[it] !== undefined && prefilled[it] !== "")
    .filter((it) => consumers[it].volt === 230)
    .map((it) => 1)
    .reduce((r, v) => r + v, 0);
  const extraCount = extra
    .filter((it) => it.hours !== "" && it.watt !== "" && it.volt !== "")
    .filter((it) => it.volt === 230)
    .map((it) => 1)
    .reduce((r, v) => r + v, 0);
  return prefilledCount + extraCount > 0;
}

export function getMaxWatt({ extra, ...prefilled }) {
  const prefilledMax = Object.keys(consumers)
    .filter((it) => prefilled[it] !== undefined && prefilled[it] !== "")
    .filter((it) => consumers[it].volt === 230)
    .map((it) => consumers[it].watt)
    .reduce((r, v) => Math.max(r, v), 0);
  const extraMax = extra
    .filter((it) => it.hours !== "" && it.watt !== "" && it.volt !== "")
    .filter((it) => it.volt === 230)
    .map((it) => parseFloat(it.watt))
    .reduce((r, v) => Math.max(r, v), 0);
  return Math.max(prefilledMax, extraMax);
}

function getVoltage({ features }) {
  const voltage = features
    .filter((it) => it.name === "System Spannung" || it.name === "Spannung")
    .map((it) => it.value);
  if (voltage.length === 0) {
    return 0;
  }
  const match = /(\d+) V/.exec(voltage[0]);
  if (!match) {
    return 0;
  }
  return parseInt(match[1]);
}

export function getInverter({ devices, set }) {
  if (!needInverter(devices)) {
    return Promise.resolve({
      products: [],
    });
  }
  const minReqWatt =
    "freezer" in devices && devices["freezer"] !== "" ? 600 : 0;
  const body = {
    watt: Math.max(minReqWatt, getMaxWatt(devices)),
    volt: getVoltage(set),
  };
  return fetch(`${API_URL}/inverters`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

function getNumPanels({ features }) {
  const panels = features
    .filter((it) => it.name === "Anzahl Module")
    .map((it) => it.value);
  if (panels.length === 0) {
    return 0;
  }
  return parseInt(panels[0]);
}

const panelTypes = {
  "Monokristallin mit Alurahmen": "starr",
  "flexibel mit SunPower-Zellen": "flex",
  "faltbarer Solarkoffer": "faltbar",
};

function getPanelType({ features }) {
  const types = features
    .filter((it) => it.name === "Solarmodul-Typ")
    .map((it) => it.value);
  if (types.length === 0) {
    return null;
  }
  return panelTypes[types[0]];
}

const mapOption1 = {
  "Auf das Dach": "Dach",
  "An den Masten": "Masten",
  "An die Wand": "Wand",
  "Auf den Boden": "Boden",
  Anderes: "Anderes",
};

const mapOption2 = {
  Dachziegel: "Dachziegel",
  Bieberschwanzziegel: "Bieberschwanzziegel",
  "Welleternit- oder Blechdach": "Blechdach",
  "Dachpappe oder Dachschindeln": "Dachpappe",
  Flachdach: "Flachdach",
  Steinplatten: "Steinplatten",
  "Mastdurchmesser 4 bis 6 cm": "4 bis 6 cm",
  "Mastdurchmesser 6 bis 8 cm": "6 bis 8 cm",
};

function getTopic({ scope, set, villaInstallation }) {
  const panelType = getPanelType(set);
  if (scope === "campervan") {
    return `camper ${panelType}`;
  }
  if (scope === "villa") {
    let { option1, option2 } = villaInstallation;
    option1 = mapOption1[option1];
    if (option1 === "Anderes") {
      return `Alphütte - ${option1} - ${panelType}`;
    }
    if (option1 === "Wand" || option1 === "Boden") {
      return `Alphütte - ${option1} - ${panelType}`;
    }
    option2 = mapOption2[option2];
    return `Alphütte - ${option1} - ${option2} - ${panelType}`;
  }
  if (scope === "schiffe") {
    return `schiffe ${panelType}`;
  }
  return scope;
}

function getVillaOptions({ scope, villaInstallation }) {
  if (scope === "villa") {
    let { option1, option2 } = villaInstallation;
    option1 = mapOption1[option1];
    if (option1 === "Anderes") {
      return [option1];
    }
    if (option1 === "Wand" || option1 === "Boden") {
      return [option1];
    }
    option2 = mapOption2[option2];
    return [option1, option2];
  }
  return [];
}

function getMaterialsAPI({ topic, panel_type, num_panels }) {
  const body = {
    topic,
    panel_type,
    num_panels,
  };
  if (!body.num_panels) {
    return Promise.resolve({
      products: [],
    });
  }
  return fetch(`${API_URL}/installation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

export function getMaterials({ scope, set, villaInstallation }) {
  return getMaterialsAPI({
    topic: getTopic({ scope, set, villaInstallation }),
    num_panels: getNumPanels(set),
  });
}

export function getCart({ set, inverter, materials }) {
  const body = [];
  body.push({
    id_product: set.id_product,
    quantity: 1,
  });
  if (inverter) {
    body.push({
      id_product: inverter.id_product,
      quantity: 1,
    });
  }
  if (materials.length !== 0) {
    materials.forEach((item) => {
      body.push({
        id_product: item.id_product,
        quantity: item.installation_material_quantiy,
      });
    });
  }
  return fetch(`${API_URL}/cart`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

export function sendNotifyOrder({
  scope,
  devices,
  solarmodule,
  battery,
  montage,
  villaInstallation,
  balcony,
}) {
  if (SUPPRESS_NOTIFY) {
    return Promise.resolve(true);
  }
  const body = {
    scope,
    devices,
    solarmodule,
    battery,
    montage,
    villaInstallation,
    balcony,
  };
  return fetch(`${API_URL}/notifyOrder`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return null;
  });
}

export function sendNotifySummary({
  scope,
  devices,
  solarmodule,
  battery,
  montage,
  villaInstallation,
  balcony,
}) {
  if (SUPPRESS_NOTIFY) {
    return Promise.resolve(true);
  }
  const body = {
    scope,
    devices,
    solarmodule,
    battery,
    montage,
    villaInstallation,
    balcony,
  };
  return fetch(`${API_URL}/notifySummary`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return null;
  });
}

export const sendNotify = sendNotifyOrder;

export function getReport({
  scope,
  devices,
  solarmodule,
  battery,
  montage,
  villaInstallation,
  set,
  inverter,
  materials,
  contacts,
}) {
  const body = {
    scope,
    devices,
    solarmodule,
    battery,
    montage,
    villaInstallation,
    set,
    inverter,
    materials,
    contacts,
  };
  return fetch(`${API_URL}/report`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}

function getModuleType(solarmodule) {
  const panel_type = [];
  if (solarmodule.flex) panel_type.push("flex");
  if (solarmodule.starr) panel_type.push("starr");
  if (solarmodule.faltbar) panel_type.push("faltbar");
  return panel_type;
}

export function getSets2({
  scope,
  devices,
  solarmodule,
  battery,
  villaInstallation,
  balcony,
}) {
  const minReqWatt =
    devices && "freezer" in devices && devices["freezer"] !== "" ? 600 : 0;
  const body = {
    watt_hours: getSumWatt(devices),
    battery: battery ? battery.haveAlreadyNo : undefined,
    battery_type: battery ? (battery.type || "Lithium") : undefined,
    panel_type: getModuleType(solarmodule),
    watt: needInverter(devices) ? Math.max(minReqWatt, getMaxWatt(devices)) : 0,
    scope: scope,
    villa: getVillaOptions({ scope, villaInstallation }),
    balcony,
  };
  return fetch(`${API_URL}/sets2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((resp) => {
    if (!resp.ok) {
      return Promise.reject({
        message: resp.statusText,
        status: resp.status,
      });
    }
    return resp.json();
  });
}
