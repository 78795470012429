import * as React from "react";

function ImgFactory(props) {
  return (
    <img
      width={28}
      height={28}
      alt="icon"
      {...props}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAOKnpUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHja3ZppciOxsYT/4xQ+AvblOFgjfIN3fH8FNCmSkkbLPIfDFkci1WyiC1VZmVmtUfP/
/rnUP/jyJWXlQ8qxxKj58sUXW3mR9fkq+6fRfv/cX+72nnk+ru5vWA45OfP8mup1fuV4ePvAfZ32
fFzl6x2br4XMfeETgVxZXo/HIDluz3Hjr4XKPC9iyekx1HYt1K8TdyjXt3/b3v6S39XTgUSWRuBC
ztrpjNP7Zz4ROPm2rvKc+Gld4TxyxmvjnNpv5GsxEvK0vduz1o8Jekry7ZV6zf791Uvybb2Ou5dc
xitHvPjwDRNejrv7ZewTHO4R2Zc3urHvtnN9rzXyWvPsrvpIRuOFqJ1sc1uGExspd/tjkUfiO/A6
7UfhkXXVnZIP3XXj0U0xlqosZbwZpppl5n7uphOit9Mmnq3t1u1j2SVbbKcwxnl5mGWTK25QNeu6
nco5Dtt7LGZft+zrdZO58jCcag2LmV3+Tx7qT2/+5KHW6pIio/M9V8RlJeGEIZWTn5xFQcy66hZ2
gm+Pq/z6AT8CVc9pkubMBqtuZ4kWzBu23K6z47zA82kho9K4FiBFXDsQjHFUQEfjgolGJ2uTMeQx
U6BK5NZ526iACcEOgrTeuWhVstnKtflMMvtcG2y0chhuohDBRXorU6FKsbwP4Cf5DIZqcMGHEGJI
IatQQo0u+hhijCkKydXkkk8hxZRSTiXV7LLPIceccs4l12KLgwNDiSWVXEqp1arKhSprVc6vHGm2
ueZbaLGllltptQOf7nvosaeee+l12OEGNDHiSCOPMuo0asIU088w40wzzzLrAmvLLb/CiiutvMqq
96pdVX33+EHVzFU1uysl56V71TiqUrotYYROgtSMillvqHiSCgBoKzXT2XhvpXJSM10sTREsQQap
jRpGKkYJ/TQ2LHOv3VvlvlU3FfK36ma/qpyS0v1/VE5Ruvd1+6BqQ3Su74qdLpScakf3cU61WfGt
NT/+9vl/ZqFl2wiN3K4cko8jRrUoT8vDLSnk7KTetwSOXI7W1lCMVNqh9C73vIDprAW46ZQnguJh
+1zSml3ZlsIi9+CuZu2GvF6ThVo3+zWKMZJbk1Zx9AtAWws4zaWTvD8Q8NDXotca6++PmFFXAXoU
ejW9uuHKMXlRmNggL4AZBmgLui8TWgudHhk+5lqDh/zr1IAqutJbnmO2MGZInXiay3KBXjzQIyZW
tuwij1zbBFziheq+Lj/U7cXfPn+yUJtWtkpOCGcZu7ORQ5xNXvnYQhptPSZd3bL+kvSyrlK7EdzU
Jlayw66mY1tUmbbX1BumaL4XY6LyrZYSIfwxY/eQU6ONMEx+OnoxDb80/wao6SGZuGxP6DUmh1A8
6XKWBUzRCg2CpTw6Xlc27GkDQJPVXlxniYQFsZWn5bkaLtUbH/gn9vHhWb0e+O3z00IfIfYxdxIu
cWfX0wajDaDNk/9ogmp1V8TU4bXpa+xVtd5VmaRnFZsKSJezmg6BFzPDgqx35eFkQT2ngdL+KRF/
eFa/TUwA7S3EAblDyKGpR0iUjkUrKTjb4fAGBACQ1RFQlJ4a+8vV9Ug1aVQQRUpHBxfI01BagDR6
68n6iCOkC3vtXhAh9qTTrzM7KNuRM19mLLvBSS+NuFNBYMvpl6pFKMK4/QmGprYbNe62qLZEaZy6
GsnVeznrF8FR1KirauFUzTeqNnS4Ve2nz+pnHzATcms+HdzDqwQhWDNLCSokaKJbdQNA2G+jR36V
lncnZnAhNPW4+h1sMmbd8HahjSs84+2bfac+gAkUKkwkybMRO5ZHJzDK12aABmqvS/deFiNEnyMF
HIjuKvsu7Q8dc4HEOoTPZxOuqI3Bnl2FKyILWSBY0JFWhp8d12qobcjTxrDGQEUyHDNdtqMxNeaZ
ymJnwmIf6xr9GFdoA5mBethxbbBegiEjmOWaqxQ7eyR/TaRkYpFJn8WXCU96lsBZSM5yIMWaX2aO
4uSLxCJyNDfei0gPowxAl06oIyZRrkx81uNgIoSKjUOX/IC6sS4rYcVqijiZYZtiV5wmF53YpD9L
NTwEigUdNN+FjyMKDhzdARKwRGsO0Q7B/kYHoKg9nRYRUMimHy1As6jySnCgkiosjaGLy7fsJIuR
xmV8L+Q7DmMnTgBxbRoYuIjAYNkM35hN+oz3EkrQ1dEsEjaqAW+wwJqgr9AO6NZ46JC0z8Tkdl8E
xDKGoN8VCMAUioP09wxoEfhADhtC7l2hOo6Zb8mtjXngj6lMhMl0CXpMtgyaJAGvQk8I+Tv5Jcn8
O5MLeWdEl7HyaUnYZByToSOz644qwEC4icGQkDqd1WdWaCAeA9pbFWI0qaGBkF4epuPSaeVJhCgg
c3GfelUjJqtuuwN3CaSOl1EHVeI5EB6qJm6HVhsLKyx2mT5bY1NjwAt7UOk7RALHTpIRXTZdPptU
DWRdMFL1pSy4htPnULv0uTTA3TFs+hTR2/Rpm9AnkhebEs17siOdYC37oVHHNISxYGUmu1ETRt4W
zKELhbFXfCFSp4XbOyZC3P4qkN3xe2ej4vfYuvi9zUni+DAmmELbOTZzY5Mah9IhtrWMm4wQiwkR
i2k9BBAZRagzE0KWYcDP2cmGSagRCRvAFhFbEHyPm1fMTMOCnxFVoimic+yQaQrId1saO2fS6rVq
K4Ci7vT31RQpL2ampQlW7BieNGIV11StgHEGElp2eENSEjVBLqcoG77HUXadpC0LATnyLvR/c5cI
wHaXdP+2l6RJUpC/8ooA8DOpVG9aGdKRlye1OVpzJOESm0ericueusjpKsyB08YW70W4kGBu1dnd
NBAYOWb0cySWVj6keuPUPklrGBl8puQVkHE57BUEpNusO+wmXraiJGVAEUhD767K9BBLLRBmb3IR
6h+xFrRSsKpj090WBkbYRAQhZYuGeJRtVaZAxLXRGhOup+KYl+A8nU3JOlagIVewe86KukRGZgij
ZAcBC1qIoIgfJiku6ZZMpdUN8+1wQMIHVKLGWEK2JL1rTFDpCpWjllhedJ20bgWAFdwUaWWx+TyB
0DuPE4h1twFE3ScQ+v5j3zA+6dVtUONlUKNy3RJyRBFT7g2CXE1Kz+zDdOPFxhfEubgWTETwe4K0
5QboorqSyEZVqMNQQSiOXAghdk96kfvZos9oYwulikFcLqXGmB9cQ8tIzkyGaVx4fUvTRASUIQtt
AmtTjdhJWnfOmGHJDEUH4vIhRs3VSiN2Sg1ByIhORNjpjIIKThxNK4235cDAZVSrGSzHSCXQcAn3
iQrEXjEF1vvsMTRuVLofBRbiQn36aHWoBsrqmNK5QD7DYPQvcYeAhFqZZQ0jbcapJlgXvv3AMYu0
4ti6dIdoKwPBVtZyKavdyirDoghzEcWTXq279+jV4sRw8SBV/UvDnjZhHCU/PtavM05sLb8rudoX
/NJFfz0KqJ8PVcdsvCZE/S4j76+ifpeR9wlRv8vI+2f1u4y8T4j6XUbeJ0T9LiPvE6JORsJEaZgK
J2tmIYQxNe6trQHlYRVx/KLtcE4VO4oL6eLJ6aBoTNazdpWwTPjgM9HEZO6ONR9DQozYVRP24MGb
1bdJMy+0Z+8DppIPdbkNLYbJhsuDh3k0dIgLx36HmU8eyBosNo+3wSNpdARFiwk2LykpSK3S117U
DYZOUOGILuLENWvL36WgYYaMM1K5dosYI3kmqrGlOqo9UD148pnPmMCU0FI3+AQiQUf8RAkanokU
SfKPPJI938V1dJy/CGQSv0QB0e34p0xIIrLEgglKmFpEdl94WJXFYu+5pe9iMsyU1OUXosSbbmCg
d+Aqz3CKYeQyEZbnRHE4iAnk3/DX7KAvKJKMzkDRYxXpmLMU4xnyJn4HDe1YO2R6bZeQSdmOKwyJ
Kx1k25yn5Mc/jBmVPOB5YGyGc37h+kes2C46X+Fp7NlWK8ZIp/C/QLuK0dXM/AV8g8ASvzPRnvnt
JFTdM3rlU4ynvSr3BK4LWqJib+BCxUWNaAi1OwL3Q0cQ+NgK2rA9HmwzbPQzwJfoezUYVNQE1cqz
VrfsW0vg/L8x5d+cnBjHR9t4c43bx6lv9Fj7zv0J9fENip/fn1ASOkF2G6UnGUgo3r6x9uz00obQ
m9PL+cXo4UbyGBLeOqTWHRHKqEZhWpWa0ow7JmzUTgBhjHkmuLopYC0MhJJtuEP5Z7kgt/uuW96B
BmBgW/iffZu3M3sw0WFacBHDeid/s8PfBW+Uy0WXTm5lpCvjZT7HNQtpdhyaJjSfBOUMzaQ1hlr7
mWPlHoBiNM1AZAzbGtOz2A9G2RSr8PQfb5DvUmzmKcf532+X326Wn+lSuhymi5h1anGF1NpuvMiV
zA4pJRIgo7USrssw+Wi2MtLvP9+SfAhAYPt8u6DaJbfUZCJmrnVJ/nCQBw4P9VF4pzMaZsYlTC9z
uEDIQwQ+945COOYyMNLxjALPcbXqS+3UKd6pnQeZt+rLGPNUf6q/kbexzmap6AW85WTMgvsDpnwj
zzk4vpak+0jBoI8lgJ7GgswuII9r7TFSgPcq5+pvLd+X92q/b3DkHkJd4KgxSBus9dAyd1S/Ensz
JnQ72HuAU3HMQOaMUSFHp+cZo86fWIA2dKauvNPqkna5G0TaJerdNOHegZDR6UAbTgWk+04PjmA2
IM+Kf7uguq34twuq1xB/u6D6bM8/XVB9lcTvLqi+W5WvFlQ/LfNnC6rf4uZ1QfW3QPwPIfsr0c4X
ZyPYh7Nx3puz1/6PNsLateIVOz4cdXT7LonzYzOYG4ymfCLhdyAwlXCZEF+udd9TCMEIg8stBbl3
YP9df874L16IWq1R1L8A0QE/n9b/YakAAAGFaUNDUElDQyBwcm9maWxlAAB4nH2RPUjDQBzFX1NL
RSoOdhBRyVCdLIiKCC5ahSJUCLVCqw4ml35Bk4YkxcVRcC04+LFYdXBx1tXBVRAEP0AcnZwUXaTE
/yWFFrEeHPfj3b3H3TtAqJWYZnWMAZpum8l4TExnVsXgK4IYRAAzGJKZZcxJUgJtx9c9fHy9i/Ks
9uf+HN1q1mKATySeZYZpE28QT23aBud94jAryCrxOfGoSRckfuS64vEb57zLAs8Mm6nkPHGYWMy3
sNLCrGBqxJPEEVXTKV9Ie6xy3uKslSqscU/+wlBWX1nmOs0BxLGIJUgQoaCCIkqwEaVVJ8VCkvZj
bfz9rl8il0KuIhg5FlCGBtn1g//B726t3MS4lxSKAYEXx/kYBoK7QL3qON/HjlM/AfzPwJXe9Jdr
wPQn6dWmFjkCeraBi+umpuwBlztA35Mhm7Ir+WkKuRzwfkbflAF6b4GuNa+3xj5OH4AUdZW4AQ4O
gZE8Za+3eXdna2//nmn09wO0F3LB+nSX+wAAAAZiS0dEAJ8AgQB284SuAAAAAAlwSFlzAAALEwAA
CxMBAJqcGAAAAAd0SU1FB+YHHQYNCKNii6AAAAJdSURBVGje7Zo9a1RBFIafN64xJtl1s1toIxtI
FbXxCwQRhYiCESwUJAgiWJhGEDRR7PwX+RWSXyD2KdZW0gerNEGJJjJppliW2blzv3AzzoFpzj2z
9zzzcc6cuStjDDHLBJFLAkyACTABJsAy0ggxktQDPg8NyKYx5lMUgMBZ4MqQbiemJdpy6KZjApxz
6NqxA7YknYgFsOvQNYGZmAFngNlYADsO3ekRwScaQMUOeCwiaSjgmRzR9XgBSjrpWYrdGGbQFy07
MQDOegCjmMEWMBU7YJ4jXC6xe7xM/4mygO06ACU9kPQF6BUZFEmvJW3afFyqHuwUSB8+524DH4F7
VnWQo6+AZ8B74BKwbYz5WydgU9KkMeZPgHM3gA/AowF1Hrgntv/VAfXPKip6XyBp2gi763Hssh3x
pwWX8kMLdrOuK4tuQI7cdTi2CKwDzylwuSXprgVbKhOEygKesrM46NgC8BZ4CUwWALtlZ3y5ijQR
ApgVKaesY+eBN8CrHIWwgD3b/7oFe1xlHgwBzKoYLkq6D6wVqC5+A9ckrQAvasn0xhhvA74BxtMO
M57X1dcA/Sz/QzZ/1hfSMhdPtV9apW8TCTAB/ltpFOjzA9gY8ewcsDqk+2qbS+7YNigb9h0uWbXv
qDRN9IdC85bHtucI5e889msO+57HfquONJFn1udGnFd9Z9k8J6dG2oMVAPoKzD2Hbt9jvx/4GyHv
rizItCWNKmEWHLoLHvtFh25J0nzBc3ElQWacWi1BJro9OM7f4qer2IN94NeYAn7PrKjT/0UTYAJM
gAkwAf7HgEf3zGd2l4rW2QAAAABJRU5ErkJggg=="
    />
  );
}

export default ImgFactory;
