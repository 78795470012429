import { useState } from "react";
import ModalBase from "./ModalBase";
import { MailSent } from '../icons';
import Spinner from './Spinner';
import Dropdown from './Dropdown';

const required = (value) => (value ? undefined : "required");
const isEmail = (value) =>
  /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/.test(value)
    ? undefined
    : "not an email";
const combineValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const emailValidator = combineValidators(required, isEmail);
const anredeOptions = ['Herr', 'Frau'].map(x => ({key: x, value: x,}));

export function Modal({ open, setOpen, onSubmit, loading }) {
  const [data, setData] = useState({
    titel: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const error =
    required(data.titel) ||
    required(data.firstname) ||
    required(data.lastname) ||
    required(data.phone) ||
    emailValidator(data.email);
  return (
    <ModalBase
      open={open}
      setOpen={setOpen}
      canSubmit={error === undefined}
      hideSubmit={loading === 'pending' || loading === 'ready'}
      onSubmit={() => onSubmit(data)}
      closeOnSubmit={false}
    >
      {loading === 'pending' ? (
        <div className="w-full h-40 flex justify-center items-center">
          <Spinner />
        </div>
      ) : null}
      {loading === 'ready' ? (
        <div className="w-full h-40 flex flex-col justify-center items-center">
          <MailSent className="w-20 h-20" />
          <h2 className="font-bold text-xl">Vielen Dank</h2>
          <p className="my-2 text-center">Wir haben dir deine Solar-Offerte als PDF auf die E-Mailadresse gesendet.</p>
        </div>
      ) : null}
      {loading === 'initial' || loading === 'error' ? (
        <>
          {loading === 'error' ? (
            <span className="text-red-500">
              Ein unerwarteter Fehler ist aufgetreten
            </span>
          ) : null}
          <Dropdown
            className="mb-4"
            placeholder="Anrede"
            options={anredeOptions}
            value={data.titel}
            setValue={(opt) => setData({ ...data, titel: opt.key })}
          />
          <input
            type="text"
            className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight mb-4"
            placeholder="Vorname"
            value={data.firstname}
            onChange={(e) => setData({ ...data, firstname: e.target.value })}
          />
          <input
            type="text"
            className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight mb-4"
            placeholder="Nachname"
            value={data.lastname}
            onChange={(e) => setData({ ...data, lastname: e.target.value })}
          />
          <input
            type="text"
            className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight mb-4"
            placeholder="Email"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
          <input
            type="text"
            className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight mb-4"
            placeholder="Telefon"
            value={data.phone}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
          />
        </>
      ) : null}
    </ModalBase>
  );
}

export default Modal;
