import * as React from "react";

const SvgBoiler = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={5.75}
      y={1.75}
      width={16.5}
      height={22.5}
      rx={1.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M17 27v-9a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v9"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path fill="currentColor" d="M13 19h2v1h-2z" />
    <path stroke="currentColor" strokeWidth={1.5} d="M6 7.25h16" />
  </svg>
);

export default SvgBoiler;
