import React from "react";
import { StateContext } from "../Context";
import { getSumWatt } from "../api/api";
import { scopeNames } from "../screens/Scope";
import { consumers } from "../screens/Devices";

function formatConsumer(consumer) {
  if (consumer.type === "number") {
    return `${consumer.label} ${consumer.value} (${
      consumer.wattHours * consumer.value
    } Wh)`;
  }
  if (consumer.type === "checkbox") {
    return `Ja (${consumer.wattHours * consumer.value} Wh)`;
  }
  return "";
}

function SummaryText() {
  const { scope, devices, solarmodule, battery, montage, villaInstallation, balcony } =
    React.useContext(StateContext);
  return (
    <>
      <h1 className="font-normal">Anwendungsbereich: </h1>
      <p className="mb-2 font-bold">{scopeNames[scope]} </p>

      {devices && (
        <>
          <h1 className="font-normal">Verbraucher: </h1>
          <ul className="mb-2 font-bold">
            {Object.keys(consumers).map((c, i) =>
              devices[c] !== undefined && devices[c] !== "" ? (
                <li key={i}>
                  {consumers[c].name}:{" "}
                  {formatConsumer({ ...consumers[c], value: devices[c] })}
                </li>
              ) : null
            )}
            {devices.extra.map(
              (it, i) =>
                it.hours !== "" &&
                it.watt !== "" &&
                it.volt !== "" && (
                  <li key={consumers.length + i}>
                    {it.name} {it.watt}W {it.hours}h {it.volt}V{" "}
                  </li>
                )
            )}
          </ul>
        </>
      )}

      {devices && (
        <>
          <h1 className="font-normal">
            Bemerkungen Solarrechner (Infos für AutoSolar) Total Verbrauch:{" "}
          </h1>
          <p className="mb-2 font-bold">{getSumWatt(devices)} Wh </p>
        </>
      )}

      {balcony && (
        <>
          <h1 className="font-normal">
            Fläche:{" "}
          </h1>
          <p className="mb-2 font-bold">{balcony} </p>
        </>
      )}

      {villaInstallation && (
        <>
          <h1 className="font-normal">Ort der Installation: </h1>
          <p className="mb-2 flex flex-col font-bold">
            {villaInstallation.option1 !== "" && (
              <span>{villaInstallation.option1} </span>
            )}
            {villaInstallation.option2 !== "" && (
              <span>{villaInstallation.option2} </span>
            )}
          </p>
        </>
      )}

      <h1 className="font-normal">Gewünschter Solarmodultyp: </h1>
      <p className="mb-2 flex flex-col font-bold">
        {solarmodule.flex && <span>Flexible Solarmodule </span>}
        {solarmodule.starr && (
          <span>Feste Solarmodule mit Aluminiumrahmen </span>
        )}
        {solarmodule.faltbar && <span>Faltbare Solarkoffer - Tasche </span>}
      </p>

      {battery && (
        <>
          <h1 className="font-normal">Zweitbatterie vorhanden? </h1>
          <p className="mb-2 font-bold">
            {battery.haveAlreadyYes && <span>Ja </span>}
            {battery.haveAlreadyNo && <span>Nein </span>}
            {battery.type !== undefined && (
              <span className="ml-1">({battery.type}) </span>
            )}
          </p>
        </>
      )}

      <h1 className="font-normal">Montage: </h1>
      <p className="mb-2 font-bold">
        {montage === "self" && <span>Selbstmontage </span>}
        {montage === "self-engineer" && (
          <span>Bei dem Monteur deines Vertrauens </span>
        )}
        {montage === "partner-at-home" && (
          <span>Bei dir zu Hause durch unseren Montagepartner </span>
        )}
        {montage === "partner-at-oetwil" && (
          <span>Bei unserem Montageparter in Oetwil am See </span>
        )}
        {montage === "partner-at-port" && (
          <span>Bei dir im Hafen durch unseren Montagepartner </span>
        )}
      </p>
    </>
  );
}

export default SummaryText;
