import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import { StateContext, DispatchContext } from "../Context";
import { ButtonIconWide } from "../components/Button";
import * as icons from "../icons";
import { CogIcon } from "@heroicons/react/outline";
import Modal from "../components/VillaModal";

function Button({ icon, option, value, setValue, children }) {
  return (
    <ButtonIconWide
      icon={icon}
      active={option === value}
      onClick={() => setValue(option)}
    >
      {children ? children : option}
    </ButtonIconWide>
  );
}

const otherOption = "Anderes";
const roofOption = "Auf das Dach";
const rodOption = "An den Masten";
const options = {
  firstOptions: [
    { icon: icons.Roofing, option: roofOption },
    { icon: icons.Wall, option: "An die Wand" },
    { icon: icons.Masts, option: rodOption },
    { icon: icons.Grass, option: "Auf den Boden" },
    { icon: CogIcon, option: otherOption },
  ],
  rodOptions: [
    { option: "Mastdurchmesser 4 bis 6 cm" },
    { option: "Mastdurchmesser 6 bis 8 cm" },
  ],
  roofOptions: [
    { icon: icons.RoofTiles, option: "Dachziegel" },
    { icon: icons.Bieberschwanzziegel, option: "Bieberschwanzziegel" },
    { icon: icons.RoofLead, option: "Welleternit- oder Blechdach" },
    { icon: icons.RoofFelt, option: "Dachpappe oder Dachschindeln" },
    { icon: icons.RoofFlat2, option: "Flachdach" },
    { icon: icons.RoofStone, option: "Steinplatten" },
  ],
};
const firstOptions = options.firstOptions.map((it) => it.option);
const rodOptions = options.rodOptions.map((it) => it.option);
const roofOptions = options.roofOptions.map((it) => it.option);

function FirstOption({ onSubmit }) {
  const {
    villaInstallation: { option1, option2 },
  } = React.useContext(StateContext);
  const { setVillaInstallation } = React.useContext(DispatchContext);
  const [open, setOpen] = React.useState(false);
  const setValue = (option) => {
    setVillaInstallation(option, option2);
    onSubmit(option);
  };
  const setOtherValue = (value) => {
    setVillaInstallation(otherOption, value);
    onSubmit(otherOption);
  };
  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        initialData={{
          name: option1 === otherOption ? option2 : "",
        }}
        onSubmit={(data) => setOtherValue(data.name)}
      />
      {options.firstOptions.map((it, index) =>
        it.option !== otherOption ? (
          <Button {...it} setValue={setValue} value={option1} key={index} />
        ) : (
          <Button
            {...it}
            setValue={() => setOpen(true)}
            value={option1}
            key={index}
          >
            {option1 === otherOption && option2 !== ""
              ? "Benutzerdefinierte Eingabe"
              : "Anderes: Hier eingeben"}
          </Button>
        )
      )}
    </>
  );
}

function RoofOptions({ onSubmit }) {
  const {
    villaInstallation: { option1, option2 },
  } = React.useContext(StateContext);
  const { setVillaInstallation } = React.useContext(DispatchContext);
  const setValue = (option) => {
    setVillaInstallation(option1, option);
    onSubmit();
  };
  return (
    <>
      {options.roofOptions.map((it, index) => (
        <Button {...it} setValue={setValue} value={option2} key={index} />
      ))}
    </>
  );
}

function RodOptions({ onSubmit }) {
  const {
    villaInstallation: { option1, option2 },
  } = React.useContext(StateContext);
  const { setVillaInstallation } = React.useContext(DispatchContext);
  const setValue = (option) => {
    setVillaInstallation(option1, option);
    onSubmit();
  };
  return (
    <>
      {options.rodOptions.map((it, index) => (
        <Button {...it} setValue={setValue} value={option2} key={index} />
      ))}
    </>
  );
}

function InstallationPage(props) {
  const { next, back } = props;
  const {
    villaInstallation: { option1, option2 },
  } = React.useContext(StateContext);
  const [stage, setStage] = React.useState("default");
  const goNext = React.useCallback(() => {
    if (stage === "default") {
      if (option1 === roofOption) {
        setStage("roof");
      } else if (option1 === rodOption) {
        setStage("rod");
      } else if (option1 !== "") {
        next();
      }
    } else if (stage === "roof") {
      if (roofOptions.includes(option2)) {
        next();
      }
    } else if (stage === "rod") {
      if (rodOptions.includes(option2)) {
        next();
      }
    }
  }, [stage, option1, option2, next]);
  const goBack = React.useCallback(() => {
    if (stage === "default") {
      back();
    } else if (stage === "roof" || stage === "rod") {
      setStage("default");
    }
  }, [stage, back]);
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Platzierung"
      {...props}
      canGoNext={
        (stage === "default" && firstOptions.includes(option1)) ||
        (stage === "roof" && roofOptions.includes(option2)) ||
        (stage === "rod" && rodOptions.includes(option2))
      }
      next={goNext}
      back={goBack}
    >
      <BodyLeft className="bg-white">
        <BodyLeft.Title>
          Wo möchtest du die Solarmodule installieren?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Wähle den Ort deiner Installation aus.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight className="bg-white">
        <div className="p-2 w-auto lg:max-w-[450px] flex flex-col items-center mx-auto">
          {stage === "default" ? (
            <FirstOption
              onSubmit={(option) => {
                if (option === roofOption) {
                  setStage("roof");
                } else if (option === rodOption) {
                  setStage("rod");
                } else {
                  next();
                }
              }}
            />
          ) : null}
          {stage === "roof" ? <RoofOptions onSubmit={next} /> : null}
          {stage === "rod" ? <RodOptions onSubmit={next} /> : null}
        </div>
      </BodyRight>
    </BaseScopeRow>
  );
}

export default InstallationPage;
