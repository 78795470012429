import React from "react";

const useOnClickOutside = (ref, handler) => {
  React.useEffect(() => {
    const listener = (event) => {
      if (
        event.offsetX >= event.target.clientWidth ||
        !ref.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export const ClickOutside = ({
  parentRef,
  onClickOutsideHandler,
  children,
}) => {
  useOnClickOutside(parentRef, onClickOutsideHandler);

  return <React.Fragment>{children}</React.Fragment>;
};

const Option = ({ option, onSelect }) => {
  const onSelectHandler = (e) => {
    e.preventDefault();
    onSelect(option);
  };
  return (
    <div
      className="cursor-pointer w-full border-primary-gray border-b hover:bg-sun-yellow"
      onClick={onSelectHandler}
    >
      <div className="flex w-full items-center p-2 pl-2 border-transparent bg-white border-l-2 relative hover:bg-sun-yellow hover:text-white">
        <div className="w-full items-center flex">
          <div className="mx-2 leading-6">{option.value} </div>
        </div>
      </div>
    </div>
  );
};

const ChevronDown = () => (
  <button className="cursor-pointer w-6 h-6 text-primary-gray outline-none focus:outline-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-4 h-4"
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  </button>
);

const ChevronUp = () => (
  <button className="cursor-pointer w-6 h-6 text-primary-gray outline-none focus:outline-none">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-4 h-4"
    >
      <polyline points="18 15 12 9 6 15"></polyline>
    </svg>
  </button>
);

const Select = ({
  options,
  value,
  setValue,
  tabIndex = -1,
  placeholder = "Auswählen",
  className = "",
}) => {
  const [opened, toggleOpen] = React.useState(false);
  const ref = React.useRef();

  const onSelectHandler = (opt) => {
    setValue(opt);
    toggleOpen(false);
  };

  const selected = options.filter((opt) => opt.key === value);
  const selectedValue = selected.length !== 0 ? selected[0].value : "";

  return (
    <div className={className} ref={ref}>
      <div className="flex flex-col items-center relative">
        <div className="w-full">
          <div className="bg-white flex border-b border-midnight">
            <div className="flex flex-auto flex-wrap"></div>
            <input
              value={selectedValue}
              readOnly
              className="p-1 px-2 appearance-none outline-none w-full text-midnight"
              tabIndex={tabIndex}
              placeholder={placeholder}
              onClick={() => toggleOpen(true)}
            />
            <div
              className="text-primary-gray w-8 py-1 pl-2 pr-1 flex items-center border-primary-gray"
              onClick={() => toggleOpen(true)}
            >
              {opened ? <ChevronUp /> : <ChevronDown />}
            </div>
          </div>
        </div>
        {opened && (
          <ClickOutside
            parentRef={ref}
            onClickOutsideHandler={() => toggleOpen(false)}
          >
            <div
              className="absolute shadow top-full z-40 w-full left-0 max-h-select overflow-y-auto"
              style={{ maxHeight: "300px" }}
            >
              <div className="flex flex-col w-full">
                {options.map((it, i) => (
                  <Option option={it} key={it.key} onSelect={onSelectHandler} />
                ))}
              </div>
            </div>
          </ClickOutside>
        )}
      </div>
    </div>
  );
};

export default Select;
