import * as React from "react";

const SvgBieberschwanzziegel = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.748 16.903v1.518S18.903 20 16.576 20c-2.326 0-2.326-1.58-2.326-1.58v-1.517M5.25 16.903v1.518S5.25 20 7.576 20c2.327 0 2.172-1.58 2.172-1.58v-1.517"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M14.248 16.903v1.518S14.403 20 12.077 20C9.75 20 9.75 18.42 9.75 18.42v-1.517M16.498 13.441v1.518s.155 1.58-2.172 1.58C12 16.538 12 14.958 12 14.958v-1.518"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M16.5 13.441v1.518s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58v-3.036h-2.25M5.249 11.923H3v3.036s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58v-1.518"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M7.5 13.441v1.518s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58v-1.518M14.25 9.98v1.518s0 1.579 2.326 1.579c2.327 0 2.172-1.579 2.172-1.579V9.98M5.25 9.98v1.518s0 1.579 2.326 1.579c2.327 0 2.172-1.579 2.172-1.579V9.98"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M9.75 9.98v1.518s0 1.579 2.326 1.579c2.327 0 2.172-1.579 2.172-1.579V9.98M12 8.036s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58V5H12v3.036Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M16.5 8.036s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58V5H16.5v3.036ZM3 8.036s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58V5H3v3.036Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      d="M7.5 8.036s0 1.58 2.326 1.58c2.327 0 2.172-1.58 2.172-1.58V5H7.5v3.036Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgBieberschwanzziegel;
