import * as React from "react";

const SvgRoofLead = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 18.5s0-1-1-1c-.5 0-.75.25-.875.5M9 18.5h4m-4 0L12 6m1 12.5s0-1 1-1 1 1 1 1m-2 0L16 6m-1 12.5h2.5L20 6h-2m-3 12.5L18 6m0 0s0-1-1-1-1 1-1 1m0 0h-4m0 0s0-1-1-1-1 1-1 1m0 0H8L5 18.5h2s0-.25.125-.5M10 6 7.125 18"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgRoofLead;
