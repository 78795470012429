import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import SolarPanel1 from "../images/solarpanel1.jpeg";
import SolarPanel2 from "../images/solarpanel2.jpeg";
import SolarPanel3 from "../images/solarpanel3.jpeg";
import { StateContext, DispatchContext } from "../Context";
import { ButtonWide } from "../components/Button";

function SolarModule({ image, name, value, setValue, features }) {
  return (
    <ButtonWide
      active={value}
      className=""
      onClick={() => setValue(!value)}
    >
      <img
        src={image}
        alt={name}
        className="block max-w-[128px] object-contain mr-2 mix-blend-multiply"
      />
      <ul className="ml-2">
        <li className="text-lg mb-2">{name}</li>
        {features.map((name, i) => (
          <li className="" key={i}>{name}</li>
        ))}
      </ul>
    </ButtonWide>
  );
}

export default function SolarModules(props) {
  const { next } = props;
  const { scope, solarmodule } = React.useContext(StateContext);
  const { setSolarModule } = React.useContext(DispatchContext);
  const setValue = (value) => {
    setSolarModule(value);
    next();
  };
  const valid = solarmodule.flex || solarmodule.starr || solarmodule.faltbar;
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Solarmodul Typ"
      canGoNext={valid}
      {...props}
    >
      <BodyLeft className="bg-white">
        <BodyLeft.Title>
          Welche Art Solarmodul möchtest du verwenden?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Wähle einen Solarmodultypen aus.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight className="bg-white">
        <div className="w-full bg-white flex-grow flex flex-col flex-start items-center">
          <SolarModule
            image={SolarPanel1}
            name="Flexible Solarmodule"
            value={solarmodule.flex}
            setValue={() =>
              setValue({
                flex: true,
                starr: false,
                faltbar: false,
              })
            }
            features={[
              "+ platzsparend und leicht",
              "+ einfache Installation",
              "- Preis ist höher",
              "- müssen verklebt werden",
            ]}
          />
          <SolarModule
            image={SolarPanel2}
            name="Feste Solarmodule mit Aluminiumrahmen"
            value={solarmodule.starr}
            setValue={() =>
              setValue({
                flex: false,
                starr: true,
                faltbar: false,
              })
            }
            features={[
              "+ günstiger",
              "+ vielseitig einsetzbar",
              "- schwer",
              "- grosse Aufbauhöhe",
            ]}
          />
          {scope !== 'plugin' ? (
            <SolarModule
              image={SolarPanel3}
              name="Faltbare Solarkoffer - Tasche"
              value={solarmodule.faltbar}
              setValue={() =>
                setValue({
                  flex: false,
                  starr: false,
                  faltbar: true,
                })
              }
              features={[
                "+ keine Installationsarbeit",
                "+ gut verstaubar",
                "- produziert nur Strom, wenn geöffnet",
                "- geringere UV-Beständigkeit",
              ]}
            />
          ) : null}
        </div>
      </BodyRight>
    </BaseScopeRow>
  );
}
