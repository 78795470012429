import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import * as icons from "../icons";
import Modal from "../components/ConsumerModal";
import { StateContext, DispatchContext } from "../Context";
import {
  CogIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";

export const consumers = {
  cooler: {
    watt: 22,
    volt: 12,
    icon: icons.Snowflake,
    name: "Kompressorkühlbox",
    type: "checkbox",
    wattHours: 450,
    label: "Anzahl auswählen",
  },
  cooler2: {
    watt: 25,
    volt: 12,
    icon: icons.Snowflake,
    name: "Kompressorkühlbox",
    type: "checkbox",
    wattHours: 450,
    label: "Anzahl auswählen",
  },
  freezer: {
    watt: 50,
    volt: 230,
    icon: icons.Kuehlschrank,
    name: "230V Kühlschrank",
    type: "checkbox",
    wattHours: 450,
    label: "Anzahl auswählen",
  },
  light: {
    watt: 5,
    volt: 12,
    icon: icons.BulbOn,
    name: "LED Licht",
    type: "number",
    wattHours: 20,
    label: "Anzahl auswählen",
  },
  light2: {
    watt: 15,
    volt: 12,
    icon: icons.BulbOn,
    name: "12V LED Licht",
    type: "number",
    wattHours: 20,
    label: "Anzahl auswählen",
  },
  light3: {
    watt: 50,
    volt: 230,
    icon: icons.BulbOn,
    name: "230V Licht",
    type: "number",
    wattHours: 20,
    label: "Anzahl auswählen",
  },
  light4: {
    watt: 10,
    volt: 230,
    icon: icons.BulbOn,
    name: "230V Licht",
    type: "number",
    wattHours: 50,
    label: "Anzahl auswählen",
  },
  smartphone: {
    watt: 7,
    volt: 230,
    icon: icons.Smartphone,
    name: "Smartphone",
    type: "number",
    wattHours: 50,
    label: "Anzahl auswählen",
  },
  smartphone2: {
    watt: 15,
    volt: 12,
    icon: icons.Smartphone,
    name: "Smartphone",
    type: "number",
    wattHours: 50,
    label: "Anzahl auswählen",
  },
  pump: {
    watt: 40,
    volt: 12,
    icon: icons.Water,
    name: "Wasserpumpe",
    type: "checkbox",
    wattHours: 100,
    label: "Anzahl auswählen",
  },
  pump2: {
    watt: 30,
    volt: 12,
    icon: icons.Water,
    name: "Wasserpumpe",
    type: "checkbox",
    wattHours: 100,
    label: "Anzahl auswählen",
  },
  heater: {
    watt: 30,
    volt: 12,
    icon: icons.Heater,
    name: "Standheizung",
    type: "checkbox",
    wattHours: 400,
    label: "Anzahl auswählen",
  },
  laptop: {
    watt: 80,
    volt: 230,
    icon: icons.Laptop,
    name: "Laptop",
    type: "number",
    wattHours: 110,
    label: "Anzahl auswählen",
  },
  laptop2: {
    watt: 100,
    volt: 230,
    icon: icons.Laptop,
    name: "Laptop",
    type: "number",
    wattHours: 110,
    label: "Anzahl auswählen",
  },
  coffee: {
    watt: 1500,
    volt: 230,
    icon: icons.CoffeeMaker,
    name: "Kaffeemaschine",
    type: "number",
    wattHours: 70,
    label: "Anzahl Kaffees pro Tag",
  },
  coffee2: {
    watt: 1400,
    volt: 230,
    icon: icons.CoffeeMaker,
    name: "Kaffeemaschine",
    type: "number",
    wattHours: 70,
    label: "Anzahl Kaffees pro Tag",
  },
  boiler: {
    watt: 1000,
    volt: 230,
    icon: icons.Boiler,
    name: "Boiler-Warmwasser",
    label: "Anzahl auswählen",
  },
  boiler2: {
    watt: 800,
    volt: 230,
    icon: icons.Boiler,
    name: "Boiler-Warmwasser",
    label: "Anzahl auswählen",
  },
  stove: {
    watt: 1200,
    volt: 230,
    icon: icons.Stove,
    name: "Induktion-Kochherd",
    type: "number",
    wattHours: 1000,
    label: "Mahlzeiten",
  },
  ebike: {
    watt: 500,
    volt: 230,
    icon: icons.Ebike,
    name: "E-Bike",
    type: "number",
    wattHours: 500,
    label: "Anzahl auswählen",
  },
  tv: {
    watt: 100,
    volt: 230,
    icon: icons.Tv,
    name: "Fernseher",
    type: "checkbox",
    wattHours: 300,
    label: "Anzahl auswählen",
  },
  autopilot: {
    watt: 20,
    volt: 12,
    icon: icons.Autopilot,
    name: "Autopilot",
    type: "checkbox",
    wattHours: 200,
    label: "Anzahl auswählen",
  },
  navi: {
    watt: 10,
    volt: 12,
    icon: icons.Navi,
    name: "Navigation/GPS",
    type: "checkbox",
    wattHours: 100,
    label: "Anzahl auswählen",
  },
  radio: {
    watt: 40,
    volt: 12,
    icon: icons.Radio,
    name: "Radio",
    type: "checkbox",
    wattHours: 70,
    label: "Anzahl auswählen",
  },
};

function Consumer({
  icon: Icon,
  name,
  label,
  custom,
  value,
  setValue,
  initialData,
  onSubmit,
  onDelete,
}) {
  const active = !!value;
  const intValue = +value;
  const [open, setOpen] = React.useState(false);
  return (
    <div
      className={`group relative
        border rounded-xl
        w-36 h-48
        shadow-min hover:shadow-big
        transition-shadow duration-300
        flex flex-col
        mb-4 mr-4 sm:mb-4 sm:mr-4 ${
        active
          ? "border-sun-yellow"
          : "border-midnight"
      }`}
    >
      {custom ? (
        <div
          className="absolute right-2 top-2 text-silver-light group-hover:text-midnight cursor-pointer"
          onClick={() => setOpen(true)}
        >
          <Modal
            open={open}
            setOpen={setOpen}
            initialData={initialData}
            onSubmit={onSubmit}
            onDelete={onDelete}
          />
          <CogIcon className="h-6 w-6" strokeWidth={2} />
        </div>
      ) : null}
      <div
        className={`basis-1/2 flex justify-center items-center p-4 ${
          active
            ? "text-sun-yellow"
            : "text-silver-light group-hover:text-midnight"
        }`}
        onClick={() =>
          custom ? setOpen(true) : setValue(intValue >= 24 ? 24 : intValue + 1)
        }
      >
        <Icon className="h-16 w-16" />
      </div>
      <div
        className={`basis-1/4 flex flex-col justify-center items-center group-hover:underline text-midnight ${
          active ? "group-hover:text-sun-yellow" : ""
        }`}
        onClick={() =>
          custom ? setOpen(true) : setValue(intValue >= 24 ? 24 : intValue + 1)
        }
      >
        <span>{name}</span>
        {label ? <span className="text-sm">{label}</span> : null}
      </div>
      <div className="basis-1/4 border-t border-silver text-silver flex flex-row">
        <div
          className="basis-1/4 flex justify-center items-center cursor-pointer hover:text-midnight"
          onClick={() =>
            custom && intValue === 0
              ? setOpen(true)
              : setValue(intValue > 0 ? intValue - 1 : 0)
          }
        >
          <ChevronDownIcon className="h-8 w-8" strokeWidth={1} />
        </div>
        <div className={`basis-1/2 border-r border-l border-silver flex justify-center items-center ${active ? 'text-midnight' : ''}`}>
          {intValue}
        </div>
        <div
          className="basis-1/4 flex justify-center items-center cursor-pointer hover:text-midnight"
          onClick={() =>
            custom && intValue === 0
              ? setOpen(true)
              : setValue(intValue >= 24 ? 24 : intValue + 1)
          }
        >
          <ChevronUpIcon className="h-8 w-8" strokeWidth={1} />
        </div>
      </div>
    </div>
  );
}

export default function Devices(props) {
  const { devices: values } = React.useContext(StateContext);
  const { setDevices: setValues } = React.useContext(DispatchContext);

  const valid =
    Object.keys(consumers)
      .map((c) => values[c])
      .some((x) => x !== undefined && x !== "" && x !== 0) ||
    values.extra.some(
      (it) => it.hours !== "" && it.watt !== "" && it.volt !== ""
    );
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Verbraucher"
      canGoNext={valid}
      {...props}
    >
      <BodyLeft className="bg-white">
        <BodyLeft.Title>
          Was möchtest du mit deiner Solaranlage betreiben?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Gebe die gewünschten Verbraucher an und füge zusätzliche
          Verbraucher unter «Gerät hinzufügen» hinzu.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight className="bg-white">
        <DeviceTable values={values} setValues={setValues} />
      </BodyRight>
    </BaseScopeRow>
  );
}

function DeviceTable({ values, setValues }) {
  const maxId = values.extra.reduce((r, v) => Math.max(r, v.id), 0);
  const nextId = values.extra.length !== 0 ? maxId + 1 : 0;
  return (
    <div className="flex flex-row flex-wrap justify-start md:justify-center pl-4 sm:pl-0">
      {Object.keys(consumers).map((c, i) =>
        c in values ? (
          <Consumer
            icon={consumers[c].icon}
            name={consumers[c].name}
            type={consumers[c].type}
            label={consumers[c].label}
            value={values[c]}
            setValue={(value) =>
              setValues({
                ...values,
                [c]: value,
              })
            }
            key={`consumer_${i}`}
          />
        ) : null
      )}
      {values.extra.map((c, i) => (
        <Consumer
          custom
          initialData={c}
          onSubmit={(value) =>
            setValues({
              ...values,
              extra: values.extra.map((it, j) => (i !== j ? it : value)),
            })
          }
          onDelete={(value) =>
            setValues({
              ...values,
              extra: values.extra.filter((x) => x.id !== value.id),
            })
          }
          icon={icons.PlugSingle}
          name={c.name}
          type={c.type}
          label="Anzahl auswählen"
          value={c.hours}
          setValue={(value) =>
            setValues({
              ...values,
              extra: values.extra.map((it, j) =>
                i !== j
                  ? it
                  : {
                      ...it,
                      hours: value,
                    }
              ),
            })
          }
          key={`consumer_extra_${i}`}
        />
      ))}
      {values.extra.length < 7 ? (
        <Consumer
          custom
          initialData={{
            id: nextId,
            name: "",
            hours: "",
            watt: "",
            volt: null,
          }}
          onSubmit={(value) => {
            setValues({
              ...values,
              extra: [...values.extra, value],
            });
          }}
          icon={icons.PlugSingle}
          name="Gerät hinzufügen"
          type=""
          label=""
          value={0}
          key={`consumer_add_${values.extra.length}`}
        />
      ) : null}
    </div>
  );
}
