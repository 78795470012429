import * as React from "react";

const SvgMailSent = (props) => (
  <svg
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M92 71c-7.7 0-14 6.3-14 14s6.3 14 14 14 14-6.3 14-14-6.3-14-14-14Zm-2.8 21-7-7 1.974-1.974 5.026 5.012 10.626-10.626L101.8 79.4 89.2 92Z"
      fill="#4CBB6C"
    />
    <path
      d="M67.688 85.5H14.25l-.01-53.772 40.733 28.2a3.563 3.563 0 0 0 4.054 0L99.75 31.742v32.383h7.125V28.5a7.135 7.135 0 0 0-7.125-7.125h-85.5A7.132 7.132 0 0 0 7.125 28.5v57a7.136 7.136 0 0 0 7.125 7.125h53.438V85.5Zm24.22-57L57 52.668 22.091 28.5H91.91Z"
      fill="#E9AC1D"
    />
  </svg>
);

export default SvgMailSent;
