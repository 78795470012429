import * as React from "react";

const SvgHouse = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.533 8.867-.435-.61-.315.224v.386h.75ZM11 3.533l.436-.61a.75.75 0 0 0-.872 0l.436.61Zm7.467 5.334h.75V8.48l-.314-.225-.436.61Zm-11.859 3.2v-.75h-.75v.75h.75Zm4.267 0h.75v-.75h-.75v.75ZM4.283 19V8.867h-1.5V19h1.5ZM3.97 9.477l7.467-5.333-.872-1.221-7.466 5.333.871 1.221Zm6.595-5.333 7.467 5.333.872-1.22-7.467-5.334-.872 1.22Zm7.153 4.723V19h1.5V8.867h-1.5ZM7.358 19v-6.933h-1.5V19h1.5Zm-.75-6.183h4.267v-1.5H6.608v1.5Zm3.517-.75V19h1.5v-6.933h-1.5Zm-.85 3.95h1.6v-1.5h-1.6v1.5Zm5.242-4.484V15.8h1.5v-4.267h-1.5Zm2.133-6.4V7.8h1.5V5.133h-1.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHouse;
