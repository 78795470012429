import * as React from "react";

const SvgOfferte = (props) => (
  <svg
    id="offerte_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".offerte_svg__st0{clip-path:url(#offerte_svg__SVGID_00000102517480827464014840000015825469366869629850_)}.offerte_svg__st1{clip-path:url(#offerte_svg__SVGID_00000006682192032631706960000002645227707574012812_)}.offerte_svg__st2{fill:#ebe6d2}.offerte_svg__st3,.offerte_svg__st4{stroke-linecap:round}.offerte_svg__st3{fill:none;stroke:#000;stroke-width:8;stroke-linejoin:round}.offerte_svg__st4{stroke-dasharray:11.2295,11.2295}.offerte_svg__st4,.offerte_svg__st5,.offerte_svg__st6{fill:none;stroke:#000;stroke-width:8;stroke-linejoin:round}.offerte_svg__st5{stroke-linecap:round;stroke-dasharray:12.5144,12.5144}.offerte_svg__st6{stroke-miterlimit:10}"
      }
    </style>
    <path
      d="m58 111.2 93.7-54.6c2.2-1.3 4.9.6 4.5 3.1L141.5 146c-.3 1.9-2.3 3-4 2.3l-25-9.5-13.2 16.5c-1.8 2.2-5.3 1-5.3-1.8l-.3-19.3 51.6-65.3-62 55.3c-.8.7-2 1-3 .6l-21.7-8.1c-2.6-1-2.8-4.2-.6-5.5z"
      style={{
        fill: "#323232",
      }}
    />
  </svg>
);

export default SvgOfferte;
