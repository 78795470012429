import React from "react";
import Progress from "../components/Progress";
import Sidepanel from "../components/Sidepanel";
import ScrollSpy from "../components/ScrollSpy";
import useContainerDimensions from "../components/useContainerDimensions";
import useScrollPercentage from "../components/useScrollPercentage";
import { StateContext } from "../Context";
import * as icons from "../icons";

export function BaseScopeRow({
  scopeTitle,
  progress,
  mobileProgress,
  children,
  canGoNext,
  next: goNext,
  back: goBack,
}) {
  const scroll = useScrollPercentage();
  const atEnd = Math.abs(scroll - 1.0) < 0.1;
  const showFloating = goBack && !atEnd;
  return (
    <div
      className="flex flex-col
      min-h-full w-full"
    >
      <header className="w-full top-0 left-0 fixed shadow-lg md:shadow-none z-20">
        <BrandHeader />
        <ScopeHeader title={scopeTitle} progress={progress} />
        <ScrollSpy progress={mobileProgress} />
      </header>
      <div className="flex-grow flex flex-col">
        <div className={`h-full pt-24 flex flex-row flex-wrap flex-1`}>
          {children}
        </div>
      </div>
      {showFloating ? <FloatingFooter goBack={goBack} /> : null}
      <StaticFooter showBack={!showFloating} canGoNext={canGoNext} goNext={goNext} goBack={goBack} />
    </div>
  );
}

function ScopeHeader({ title, progress }) {
  const { scope } = React.useContext(StateContext);
  const ref = React.useRef();
  const { width, height } = useContainerDimensions(ref);
  return (
    <div className="w-full bg-midnight text-white px-4">
      <div className="mx-auto container flex flex-row items-center h-8">
        <div className="flex-1">{title ? title : null}</div>
        <div className="flex-1 h-full hidden md:block pl-0 sm:pl-8" ref={ref}>
          {progress !== undefined ? (
            <Progress
              stage={progress}
              scope={scope}
              width={width}
              height={height}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function BrandHeader() {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="w-full bg-sun-yellow text-midnight py-4 px-4">
      <div className="container mx-auto flex flex-row items-center">
        <div className="flex items-baseline">
          <a href="/" className="text-2xl-mobile md:text-2xl mr-2 outline-none">
            Solarrechner
          </a>
          <div className="text-xs-mobile md:text-base">
            powered by
            <a href="https://autosolar.ch" className="ml-2 underline outline-none">
              autosolar.ch
            </a>
          </div>
        </div>
        <div className="flex-grow" />
        <Sidepanel open={open} setOpen={setOpen} />
        <button className="outline-none" onClick={() => setOpen(true)}>
          <icons.Menu className="h-9 w-9" strokeWidth={1} />
        </button>
      </div>
    </div>
  );
}

function FloatingFooter({ goBack }) {
  return (
    <footer
      className="bg-transparent text-midnight
      w-full h-20 z-20
      hidden md:block md:fixed bottom-0 left-0 right-0"
    >
      <div className="flex flex-row h-full justify-start">
        <div className="w-1/2">
          <button
            className="w-full h-full
            hidden md:block
            outline-none
            hover:underline
            disabled:no-underline"
            disabled={!goBack}
            onClick={goBack}
          >
            <div
              className="container-1/2 ml-auto pl-4
              flex justify-start items-center"
            >
              <icons.ArrowLeft className="h-9 w-9" strokeWidth={1} />
              <span className="ml-2 font-bold text-xl-mobile md:text-xl hidden md:block">
                zurück
              </span>
            </div>
          </button>
        </div>
        <div className="w-1/2 flex flex-row items-center justify-end">
          <icons.ArrowCircleDown className="h-16 w-16 mr-4 cursor-pointer" stroke-width={0.5} onClick={() => window.scrollTo(0, document.body.scrollHeight)} />
        </div>
      </div>
    </footer>
  );
}

function StaticFooter({ canGoNext, goNext, goBack, showBack }) {
  return (
    <footer
      className="bg-sun-yellow-light text-midnight
      w-full h-20"
    >
      <div className="flex flex-row h-full justify-start">
        <div className="w-1/2">
          {goBack && showBack ? (
            <button
              className="w-full h-full
              hover:bg-sun-yellow hover:underline
              outline-none
              disabled:bg-sun-yellow-light disabled:no-underline"
              disabled={!goBack}
              onClick={goBack}
            >
              <div
                className="container-1/2 ml-auto pl-4
                flex justify-start items-center"
              >
                <icons.ArrowLeft className="h-9 w-9" strokeWidth={1} />
                <span className="ml-2 font-bold text-xl-mobile md:text-xl hidden md:block">
                  zurück
                </span>
              </div>
            </button>
          ) : null}
        </div>
        <div className="w-1/2">
          {canGoNext ? (
            <button
              className="w-full h-full px-4
              outline-none
              hover:bg-sun-yellow hover:underline"
              onClick={goNext}
            >
              <div
                className="container-1/2 mr-auto
                flex justify-end items-center"
              >
                <span className="mr-2 font-bold text-xl-mobile md:text-xl hidden md:block">
                  weiter
                </span>
                <icons.ArrowRight className="h-9 w-9" strokeWidth={1} />
              </div>
            </button>
          ) : null}
        </div>
      </div>
    </footer>
  );
}

export function BodyLeft({ className, children }) {
  return (
    <div className={`flex-auto sm:flex-1 ${className}`}>
      <div className="container-1/2 ml-auto py-8 px-4 md:pr-32">{children}</div>
    </div>
  );
}

BodyLeft.Title = function ({ children }) {
  return <h2 className="text-5xl-mobile md:text-6xl mb-4">{children}</h2>;
};

BodyLeft.Subtitle = function ({ children }) {
  return <p className="my-2 text-lg-mobile md:text-lg">{children}</p>;
};

export function BodyRight({ className, children }) {
  return (
    <div className={`flex-auto sm:flex-1 ${className}`}>
      <div className="container-1/2 mr-auto py-8 px-4">{children}</div>
    </div>
  );
}

export const toPrice = (value) =>
  value.toLocaleString("de-CH", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
