export function ButtonBase({children, active, className, ...rest}) {
  return (
    <div
      className={`
      bg-white
      border rounded-xl
      shadow-min hover:shadow-big transition-shadow duration-300
      cursor-pointer hover:underline
      group
      ${
        !!active
          ? "text-sun-yellow border-sun-yellow"
          : "text-midnight border-midnight"
      }
      ${className ? className : ''}
      `}
      {...rest}
    >
      {children}
    </div>
  );
}

export function ButtonWide({children, className, ...rest}) {
  return (
    <ButtonBase
      className={`
      ${className ? className : ''}
      w-full md:w-3/4
      flex flex-row items-center
      p-6 py-8 mb-4
      `}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
}

export function ButtonIconWide({ children, icon: Icon, onClick, active }) {
  return (
    <ButtonWide
      active={active}
      className="justify-between"
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        {Icon ? (
          <Icon
            className={`icon mr-4 w-12 h-12 ${
              active
                ? "text-sun-yellow"
                : "text-silver-light group-hover:text-midnight"
            }`}
          />
        ) : null}
        <span className="text-lg-mobile md:text-lg">{children}</span>
      </div>
    </ButtonWide>
  );
}

export function ButtonImgIconWide({ children, icon: Icon, onClick, active }) {
  return (
    <ButtonWide
      active={active}
      className="justify-between"
      onClick={onClick}
    >
      <div className="flex flex-row items-center">
        {Icon ? (
          <Icon
            className={`icon mr-6 w-12 h-12 ${
              active
                ? "text-sun-yellow filter-sun-yellow"
                : "text-silver-light filter-silver-light group-hover:text-midnight group-hover:filter-midnight"
            }`}
          />
        ) : null}
        <span className="text-lg-mobile md:text-lg">{children}</span>
      </div>
    </ButtonWide>
  );
}
