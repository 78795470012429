import * as React from "react";

const SvgBeratung = (props) => (
  <svg
    id="beratung_svg__Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>{".beratung_svg__st7{fill:#323232}"}</style>
    <path
      className="beratung_svg__st7"
      d="m130.3 77.5 2 2.3c2.3 2.6 2.1 6.6-.3 9l-36.7 36.7c-2.4 2.4-6.3 2.6-8.9.4l-2.4-2-31.8 31.8 3.2 3.3c3.2 3.3 7.8 4.7 12.3 3.9 14.7-2.6 43.1-15.6 62.2-34.7 19.3-19.3 34.9-50.7 38.6-66.4 1.1-4.6-.2-9.4-3.6-12.8l-2.9-3-31.7 31.5zM157.4 41.5l-1.8-1.8c-2.6-2.6-6.8-2.7-9.4 0l-21.7 21.7c-2.5 2.5-2.6 6.4-.3 9l2 2.3 31.2-31.2zM79 119.9l-2.5-2.1c-2.6-2.2-6.5-2-8.9.4L45.8 140a6.61 6.61 0 0 0-.1 9.3l1.9 1.9L79 119.9z"
    />
  </svg>
);

export default SvgBeratung;
