import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import * as icons from "../icons";

export function Sidepanel({ open, setOpen }) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto relative"
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-milk-white bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="ease-in duration-200"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div
            className="fixed bg-sun-yellow
             text-left
             inset-y-0 right-0
             overflow-hidden shadow-xl
             transform transition-all
             w-2/3 md:w-1/3"
          >
            <div
              className="flex flex-col
              h-full
              p-4 pl-24"
            >
              <div>
                <button className="cursor-pointer outline-none" ref={cancelButtonRef} onClick={() => setOpen(false)}>
                  <icons.Close className="h-8 w-8" />
                </button>
              </div>
              <a className="mt-8 text-2xl hover:underline outline-none" href="https://rechner.inselanlage.ch/">
                Solarrechner
              </a>
              <a className="mt-2 text-lg hover:underline outline-none" href="https://www.inselanlage.ch/">
                Worum geht's
              </a>
              {/*<a className="mt-2 text-lg hover:underline outline-none" href="https://www.inselanlage.ch/anleitung" target="_blank">
                Anleitung
              </a>*/}
              <a className="mt-8 text-2xl hover:underline outline-none" href="https://autosolar.ch">
                autosolar.ch
              </a>
              <a className="mt-2 text-lg hover:underline outline-none" href="https://autosolar.ch">
                Onlineshop
              </a>
              <a className="mt-2 text-lg hover:underline outline-none" href="https://autosolar.ch/content/22-kontakt">
                Kontakt
              </a>
              <div className="flex-grow" />
              <p className="">
                Der{" "}
                <a className="underline outline-none" href="https://rechner.inselanlage.ch/">
                  Solarrechner
                </a>{" "}
                ist ein Angebot von{" "}
                <a className="underline outline-none" href="https://autosolar.ch">
                  autosolar.ch
                </a>
                <br />
                <a className="underline outline-none" href="https://autosolar.ch/content/4-impressum">
                  Impressum
                </a>
              </p>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

export default Sidepanel;
