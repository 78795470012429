import * as React from "react";

const SvgBulbOn = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m6.521 10.352.743.106-.743-.106Zm-.016.112-.742-.106.742.106Zm10.974-.112.742-.106-.742.106Zm.016.112-.743.106.743-.106ZM15.2 20v.75a.75.75 0 0 0 .75-.75h-.75Zm-6.4 0h-.75c0 .414.336.75.75.75V20Zm-1.191-5.355.593-.46-.593.46Zm8.782 0-.593-.46.593.46ZM9.6 23.95h4.8v-1.5H9.6v1.5ZM5.779 10.246l-.016.112 1.485.212.016-.112-1.485-.212ZM12 4.85a6.284 6.284 0 0 0-6.221 5.396l1.485.212A4.784 4.784 0 0 1 12 6.35v-1.5Zm6.221 5.396A6.285 6.285 0 0 0 12 4.85v1.5c2.381 0 4.4 1.75 4.736 4.108l1.485-.212Zm.016.112-.016-.112-1.485.212.016.112 1.485-.212Zm-1.253 4.746a6.272 6.272 0 0 0 1.253-4.746l-1.485.212a4.772 4.772 0 0 1-.954 3.616l1.186.918Zm-2.534 2.338V20h1.5v-2.558h-1.5Zm.75 1.808H8.8v1.5h6.4v-1.5ZM9.55 20v-2.558h-1.5V20h1.5Zm-3.787-9.642a6.272 6.272 0 0 0 1.253 4.746l1.186-.918a4.772 4.772 0 0 1-.954-3.616l-1.485-.212Zm3.787 7.084c0-1.3-.692-2.408-1.348-3.256l-1.186.918c.614.794 1.034 1.562 1.034 2.338h1.5Zm6.248-3.256c-.656.848-1.348 1.955-1.348 3.256h1.5c0-.776.42-1.544 1.034-2.338l-1.186-.918ZM11.25 0v3.2h1.5V0h-1.5ZM0 12.75h3.2v-1.5H0v1.5Zm20.8 0H24v-1.5h-3.2v1.5ZM5.33 5.87l-2.4-2.4-1.06 1.06 2.4 2.4 1.06-1.06Zm14.4 1.06 2.4-2.4-1.06-1.06-2.4 2.4 1.06 1.06Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBulbOn;
