import * as React from "react";

const SvgSize2M2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M147.4 167.4v-90c0-.1-.1-.2-.1-.4 0-.1-.1-.3-.1-.4 0-.1-.1-.2-.2-.4-.1-.1-.1-.2-.2-.3-.1-.1-.2-.2-.3-.4-.1-.1-.1-.1-.1-.2l-.1-.1-.3-.3c-.1-.1-.2-.2-.3-.2-.1-.1-.2-.1-.3-.2-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.3-.1-.1 0-.3-.1-.4-.1H42.5V46.7h-8v124.6c0 2.2 1.8 4 4 4H174v-8h-26.6zM133 82.1l-90.5 82v-82H133zm-82.1 85.3 88.5-80.2v80.2H50.9z" />
  </svg>
);

export default SvgSize2M2;
