import * as React from "react";

const SvgStove = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={4.75}
      y={2.75}
      width={18.5}
      height={22.5}
      rx={1.25}
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <circle cx={14} cy={11} r={5.25} stroke="currentColor" strokeWidth={1.5} />
    <circle cx={14} cy={11} r={2.25} stroke="currentColor" strokeWidth={1.5} />
    <path stroke="currentColor" strokeWidth={1.5} d="M5 19.25h18" />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M7.75 22.25h3.5M14.75 22.25h-.5"
    />
  </svg>
);

export default SvgStove;
