import * as React from "react";

const SvgBoot = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M167.5 164.54a5.605 5.605 0 0 0-7.84 1.26c-1.12 1.68-2.8 2.94-4.62 3.92-1.82.84-3.78 1.4-5.88 1.26h-.28c-2.1 0-4.06-.42-5.88-1.26-1.82-.84-3.36-2.24-4.62-3.92-2.24-3.08-5.18-5.6-8.68-7.42-3.36-1.68-7.14-2.52-10.92-2.52-3.78 0-7.56.84-10.92 2.52-3.36 1.68-6.44 4.2-8.68 7.42-1.12 1.68-2.8 2.94-4.62 3.92-1.82.84-3.78 1.4-5.88 1.26h-.28c-1.96 0-4.06-.42-5.88-1.26-1.82-.84-3.36-2.24-4.62-3.92-2.24-3.08-5.18-5.6-8.68-7.42-3.36-1.68-7.14-2.52-10.92-2.52-3.78 0-7.56.84-10.92 2.52-3.36 1.68-6.44 4.2-8.68 7.42-1.12 1.68-2.8 2.94-4.62 3.92-1.82.84-3.78 1.4-5.88 1.26h-.28c-2.1 0-4.06-.42-5.88-1.26-1.82-.84-3.36-2.24-4.62-3.92a5.605 5.605 0 0 0-7.84-1.26 5.605 5.605 0 0 0-1.26 7.84c2.24 3.08 5.18 5.6 8.68 7.42 3.36 1.68 7.14 2.52 10.92 2.52 3.78 0 7.56-.84 10.92-2.52 3.36-1.68 6.44-4.2 8.68-7.42 1.12-1.68 2.8-2.94 4.62-3.92 1.82-.84 3.78-1.4 5.88-1.26h.28c1.96 0 4.06.42 5.88 1.26 1.82.84 3.36 2.24 4.62 3.92 2.24 3.08 5.18 5.6 8.68 7.42 3.36 1.68 7.14 2.52 10.92 2.52 3.78 0 7.56-.84 10.92-2.52 3.36-1.68 6.44-4.2 8.68-7.42 1.12-1.68 2.8-2.94 4.62-3.92 1.82-.84 3.78-1.4 5.88-1.26h.28c1.96 0 4.06.42 5.88 1.26 1.82.84 3.36 2.24 4.62 3.92 2.24 3.08 5.18 5.6 8.68 7.42 3.22 1.68 7 2.52 10.64 2.52h.7c3.64 0 7.28-.84 10.64-2.52 3.36-1.68 6.44-4.2 8.68-7.42 1.96-2.52 1.4-6.02-1.12-7.84z" />
    <path d="m24.14 160.62 10.92-2.24-7.28-36.54h119.84l-13.58 27.02 10.08 5.04 17.64-35.14c.84-1.68.84-3.78-.28-5.46-.98-1.68-2.8-2.66-4.76-2.66h-27.16L101 67.94c-.98-1.54-2.8-2.52-4.62-2.52H56.76v-24.5c0-3.08-2.52-5.6-5.6-5.6H38v11.2h7.56v18.9h-9.52c-3.08 0-5.6 2.52-5.6 5.6v39.62h-9.52c-1.68 0-3.22.7-4.34 2.1s-1.54 2.94-1.12 4.62l8.68 43.26zm17.5-83.86h51.8l22.68 34.02H41.64V76.76z" />
  </svg>
);

export default SvgBoot;
