import * as React from "react";

export function ScrollSpy({ progress }) {
  return (
    <div className="md:hidden w-full h-1 bg-white">
      <div
        className="bg-sun-yellow h-1"
        style={{ width: (progress * 100).toPrecision(3) + "%" }}
      />
    </div>
  );
}

export default ScrollSpy;
