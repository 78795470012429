import * as React from "react";

const SvgRoofing = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.38 8.524h2.38L7.74 9.93l-2.36 2.124V8.524Zm6.583-.777L2.571 16.03l.99 1.112 8.44-7.446 8.44 7.446.987-1.112-9.39-8.284L12 7.714l-.037.033Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRoofing;
