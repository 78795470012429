import * as React from "react";

const SvgNavi = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M124.2 87.5c0-9.9-8-17.9-17.9-17.9s-17.9 8-17.9 17.9 8 17.9 17.9 17.9 17.9-8 17.9-17.9zm-28.3 0c0-5.7 4.7-10.4 10.4-10.4s10.4 4.7 10.4 10.4-4.7 10.4-10.4 10.4-10.4-4.6-10.4-10.4z" />
    <path d="M143.6 130.8H122c8.9-11.6 19.1-28.1 19.1-42.8 0-22.6-14-37.8-34.8-37.8S71.5 65.4 71.5 88c0 14.7 10.2 31.2 19.1 42.8H69l-13 31.7h100.5l-12.9-31.7zm-37.3-73.1c16.6 0 27.3 11.9 27.3 30.3s-19.9 41.8-27.3 49.8C99 129.8 79 106.4 79 88c0-18.7 10.5-30.3 27.3-30.3zm-32.2 80.6h22.6c3.8 4.4 6.6 7.3 7 7.6l2.7 2.7 2.7-2.7c.4-.4 3.2-3.2 7-7.6h22.6l6.8 16.7H67.2l6.9-16.7z" />
  </svg>
);

export default SvgNavi;
