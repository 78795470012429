import * as React from "react";

function ImgHouse2(props) {
  return (
    <img
      width={28}
      height={28}
      alt="icon"
      {...props}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA3CAYAAABZ0InLAAAYD3pUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHja5ZpXltw4lobfsYpZArxZDuw5s4NZfn8/GJmKLEklqaufeipLaSIYJHjNby5o9v/9
7zH/w3+55mpiKjW3nC3/xRab7/xS7fNfu9+djff7/S98vOe+vm5Ceb3heSnoyOfP0l/Hd15P3z7w
eZ7x9XVTX+/4+jqR+zzxswJdWb+v90Xyun9ed/F1orafX3Kr5X2p43Wi+TrwLuX1L367vfuf/jZf
XihEaSUuFLzfwQV7v9dnBUH/fOj8LHz3oXEcMeP3GKLhhwsf90pAvtzex09r3wP0Jcgfv5m/Rv+M
Hwff99cR4S+xzK8Y8csP33DpL6+Hz+v7L+XwuSL/9Y22XPvudl7/zln1nP3cXY+ZiOZXRd1gu4/T
cCA3FsP9WOar8C/xe7lfja9qu52kfNlpB1/TNefJyjEuuuW6O27fn9NNlhj99oWf3k8f7ms1FN/8
JEtOOQrRHV9CC4us+TD9NiHwsv9ci7vXbfd601WuvByHesfJ3E3/T77M3735J1/mnKkQuRvMcWPF
urzqmmUoc/rOUSTEnVfe0g3wx9cr/fatsFSqkcMU5soNdjueU4zkvtVWuHkOHJf4+bSQM2W9TkCI
uHZiMS6QAZspeJedLd4X54hjJUGdlfsQ/SADLiW/WKSPIWRviq9e1+Yzxd1jffLZ62WwiUSkkOmt
SoY6yYoxUT8lVmqop5BiSimnkqpJLfUccswp51yyQK6XUGJJJZdSamml11BjTcBgqbW22ptvAQxM
LbfSamutd286F+qcq3N855XhRxhxpJFHGXW00SflM+NMM88y62yzL7/CAiZWXmXV1VbfzmyQYsed
dt5l1912P9TaCSeedPIpp552+mfWXln97usPsuZeWfM3UzqufGaNV00pH6dwgpOknJExHx0ZL8oA
Be2VM1tdjF6ZU85s8zRF8iwyKTdmOWWMFMbtfDruM3ffMvdbeTOp/lbe/K8yZ5S6/0TmDKn7Pm8/
yNoSz82bsacLFVMb6D6O6b4a/lnLt3/68//VieiQU3s5Lp7IP9v3KD3kuvqZx5L87FrKYAh40rov
oucWo6mImRGtm5TzdLusE/hruO3bqatsN46tp9a9TwzjDL9SjqOPtNpY21a4uxYwy4w66QEbwPiS
+7Hx1BF2SCuUWdr2+kgKcVS/t6veDx9HyWl0Kjr76dYaPuQRjJ2spJSTVE8UzD60xJjNU0x77zLG
6C24sXPbwGQY+SoKLnLFxZlcaglq00mFvxKrPz3oe9xajT+0yyqzldXzbsmVtnyclcjkA93FvEas
NOnhV27JFJeOrylNPp3yiiPmUfl/+ZMsy3FtASchNpZPx84EcW7bEt+ltWYZgA38Y+Z+EgVLp/ak
qihVtXXdulKldeZMa7uc2iZViV4urVWYf5KpGqZLxk3eW8OlLUqHRVCnap/D+aZtPecOhoBLre/T
CqumpWcIvqwGaZOKCC9DkAgAJJ/bjaSvmTghJxERjkHu+QO5EM5pPpW77pl3fda/KAxWuyksYNQ0
P+4bGwSybletiHddPW6ARWXmcCiLPe2KYa92MhW0S9k7x3ry3nOvM3ZHREACC2zxoytst8b/jZ/m
/YUx89l+npnaKmPa8tSjP3GMuELO1ScVJKEfva7VanBEjcX2Da+5ikQaK1dLV8xs6bRWUo/lANWU
mON8mTIIcykki9cjp9yAO0ULOns38jGUYyTDlNwJM511gFNB7uJja8e2iQmZ6bTgPDTlmntSVKHS
ndR/6SkVNbx5Oj7TYnEdS1VIvJWa8h4JSKZtkq+5cEzai4ZdGRpJvjvnhqdbEXQ7zVPMiSpMN4iQ
0kIxzFARGfsm+95djloe18k04pksjJ6FojL8UmdeKdGhWIjR9ko7HbJPO/vTN3ex6CUlfdNLe/eV
U2zd0XeWINEfvYE59Ep1K4/dxjG6Ixc6MEOjjpLoBzHPgZwyjF36bq4CAPBroVtHhkFq4g8fioN3
3B6KrzebpkAdUMFlUuBgF1VcSVTSrdGRizTtuihKwGHbW92xrBy2t9z2PCdzq8lw/UONI3EFf3gW
qvYgeSalS1Wx/FYJ+AlQ7EatR6R0bZz7rl+nhdM7WQvtvBA7rCe8+b6NeuJoSv5eUQKRk3GiyQUP
gKJj1rjHaLGq7H9GIi8I6uaP6eLHbNGMzufSLSAKJh1OUCZYtKrEALUc8lJIB/UIUC9bwxg5T8wD
PbEodDCpzG0kk/4B5gM65JquM+KfOt5QR335DXXI+RvqzDfQAcYosxn6SgOPhBhFClEqk4ZH79H7
uWvVoO9GdfWNotxItDhnRmjx0T3xdHED53myqFRFhhNTw82AFSzJeUpJSUbwUkQdLGgkP58DLYD+
rm5wgou4MebYYNQOYdl9MRZVC5Xp9BeHK1RN+bPag2iDD3ZctM8oTVjeRpsKtW5UjYoFr/xWwizL
yI/TX+0Q6wSO7AHokXzaGN1ZPZ/hbEhAESXuRAr5TEEq6HXJMgJ5O8P9QTgSFRxELQvfDnxhfeAb
GpsGG0L9s8XIY56WRtDt6gg3CL7q3w/DQZ4VgacQHK2MaKxEqat74RYM66AnG1buECRumPec2xA2
YYTpQFkV0zKOm5klSoB2gS71THGu4AEaalCSpCODS8EUo6FxVoDkQQaQfyqeGrNRGTZIFTwslOEl
Wn3UJ+C9IMTMoDi6FdDNx7VHJKmjPYTOx7frY6jmQqkOxTYyCgqKBazdQB2ME/o4KWmtNJLStREd
FtUdtnNgZxh2FWiLaICW+Arw03AkaqVjPiwLZUWJRbREJNFeMX3oAaHfvjAz5MlZ/RTCcNim1kbq
ZuxqO5r8vigoegMiXnmg6NvZfvrT/OiNUpfYViKwAwfUDTJv9LRbyegTGiMSj1bshAjIarB42r1H
dRInOQghSlf7iAEIGayG+iEU+dZ9pJciGfClDckUNXldNoEkZA1dBOcAOliQ3eh4iguCpEW4wsKQ
kAD+mJTvRMx2eiU3MsXV26oNYLGwbvMGeOwUUBK8QTqZQhKG5byWCG4sXDunrpT7IwBBG99rxt8g
KXD4EJyFlIARYGDlNigXihz7FFRr9Hq2A21RWSuaYVaLvlsTJ0gJ1gkvddfPcIeXN6RlELAWqKbJ
FctAuSuUhUJRKOPuc7rae99W3OzqRIWuLdDmXuZylDKaYR4Dqw/WkCllQLtSpbT5PrMekHfcOGZI
XXEqZDCXi9DIVhtEorXBamjhZNCIjXqi+hEihYp33tcdaDlukzIdjVvffXBLm1ptkkAAXRGESZrj
KKOmM9DRZdwX4YZ3woU2+C5IBKAi9oDo7+/pjQ6gdczD5QOczdIKpJV2oU0dN0g/l+F6xJ4jgf31
5cTRixnQ7WXOWYLUdR/b1MIdTtZtpcsJMU44oRIoYUKEYV8xTcJwQkvUSMsL3iMZwGqg6Epzgl3X
kX54ILrtsx0ipUD5eBxJXK3zUeBiO/Q6yj2j9fH5nEdydGoqlPFDICAFmWACgsSdzi5JsDyiAI0E
ZTRFcOD9Ee+B0susO1BTsuJYeqTRBWUH/plH53HDC4QlfTtTaRdY2xYfueUwCnAk35vIBYwHkPho
xc1h67JTMSWTCBRNvVXRmJYyAsTJqhzM1zuBsXFzzTatpAa/FKEE0Srji8gxX1WOGvYqnyYtBSkC
WuXRQlDz0STHuteZvv40P3hDbFTXmPcEOFpiBILAemqtRWigWfhxS8H7w9005IzZJ2NohE4L5IbI
nUdUl5g5w4bkoa3RCrhBG2CRnAXPG1I3p1mBZ81REeyNXiNGMUISSGZOtEoQiIAJrADBvwnJqHRO
khSWJToe1XWCHXgM6PuqnNWjsZ58SnFevkWXqHUVM7kxmYl7g9N70IT7AmYSnEjbwL8F1N2VSoS3
zFB5FBUHXv28nBKO6xr3LOcWAF2KA2y7yo0w9YH8v9JVeAkEDjdMEOMCVMRMpd3DBDhs02irzqLy
X7RfeQV5UKecIgKJC5vFsrlEliM15B6zuyL3ACrSCspU9eoSYOECJtQkcwQqVPp5k44pz6FxVsKR
HGx08kaeKXvbuEOCgGuwQ/4afEfnQqYO6w/aKnRqGDodZ4+aR0TEA/yj04CbzIqyHBr5TRKReMIr
TvbgFPlqZBxclruhbVCiYA2nTRyvAZjVCYdk7TEDCqN3EgkSLFUEDqkhPieVTDeDKMjbfSfsDpXp
4SXwKaooUbaYSOAYsDFjQApbKyIO4zdc8Pea7Coy83NJJl/yJsluugEjieqrNyED9E+8dRan4fah
pwjSYYkPXBkkqyukHAX5UrSrqK8DktHiX0F7jaoAeZbmLLQ5ExLW0O8R0CC76DTNKtCWcbkuW0WN
w6WSsU77CpedhYawIhF/rQxxhSwtBnNZVGxX7dPMWEAlBQ9B417RjAPGmS9qeyytsQv2KD0sXsDD
7GPJVDPYVdhDQI1F31kTmdATtBsA7C5t2btGQUSjdld1LwSeM9OglL3vBY6cZI2iq8g0qfqQJqJ1
eA1gHM4vATjcqK+xcT+Jol0psl5wsmLe4QS08LrmdFUjTbwUGQIIYtzCAxUw+PtmDhZfsLpDgW4U
bi63yR1MTagu5NJwNKehHg5eBK/r7HAywlOdACAW2oSCQ2VMyjdjbA7iRK205onwNhoLCnDXJBUj
/1A0zCAe+PtOq8AVFZEsFQEVngAlCHQAzQpUePgEtqR9uGwD6NuZoWzj22WTBbqqjhz9CVkj8Fqc
UsAEqtQCkvtgtwZMiASZjlW/SgDzpgnmEHtH+mRf7obhekZVwtKwpWYOQeIvai6+49Ve0c0s1Xay
CZH8Ibvh6SyDzFp7gv8zNhHAAXud63wGJT8rPkyKTwO/gCYgNPgNMf+G+8UqGbrU+J4u9gh+jQb4
H7UT853MtKbJTPiYzNzBDNUTBaRB/ee3Af2uIwgpkalFK/mqEoLVXbzRqbao7VEXWAKsw3kJ8typ
YoppIeLaMOUZXsIfUVSb+BBE0QXwvbth7/TSxqzR4CyjCtQToj2okql5jlZpRpMAyyy8UP9r+su5
iu2KpUd5AGl5qLR4WUiO8CRzSIcG1HOXBcXgF+c2NB6Sol8bQFfJBaSvLkB1Tx4oTIkrDOLyIqcm
B0gvS/jgSQ09AW9W+btNpgGxA0JoNkW5XKFJUrFZ4hRP4eOp0BvUAXUN3WN7QRwgBYQcMTa/IcvQ
VR4gZKR4oUIspGgANsAxXz08xY6ykMEeScCpuQVVgTwwNCSCck0N+aZbgdKmwf58omlE+LTWgfbw
PuiDDRrR8xQ4rJk1LsX3ZLkXD0xWBAtSbk4ElASJFDXVVaoRm6H1tC1VD1qUG0nTy/3jOukQXu/a
FSsua5xBJqxkhDvkCucAVwBNsQxULZdLtCrJ3XCUdgSQ4DTY18mqhuVvk1Wk7Z2sfg5WTaZ7Go1D
Ooght3kHJIMc7CEkCZKkwMHafolLixs0q5rtbllNQXg44FHCadC4oli0m7QkRm9XyiVsTSyP9tKS
NMjQAC+9D/C8V8NH8hczCJkUpQQIRvlKFA9NfBx/oXKxeGD0fZqBJkGQy34nOVltmFQp5P5oROPv
5hvEuBr3FoEzMnI0nIXeAX0XBDKa6x5UYrsD3oC2WeoIJDpwKvnmjdDC3TkIHMPNcD7tIS5NIkMY
UVCPM5RfXHIaWAKLBeIM9VYNK5TebsgaxN9QMNOR6YW980QbLo1zs6ZEr2kuYH/9LhCIk7cavQKB
wHFGEO9lGpoSrzcztUY2qqvyzHFrmyEuDX8+JwLaC0CgufDIU3oA4tkv4jHSl17u5WGegbKE1S7z
0Mp5i3nWZR7oXHgYZKc8RO25BmQh+oyF9BfkYNaEsZKmkVMserLEaTJyleDBhEozYHmhULQIimH1
+5zFpUANjFihUYQJzlBkoKgyrEaEssN0rxSD5uwDIirCw7zk0VPUbAGUouZnR4CP2ozL2sPxILjD
cnIW7fdihbTVksoNTbx62c7yMDK2xC376JdPRj6GlngoOWRZgvZQMvdLL9Y70FVDbADoyE0q4/JN
pBy6uCoO4J2eYJdNXVSih0II9ytphIjXoLhJNRxD9ErgwoNm4ko5aYN26kEOegRwcShXo9HvpD7G
5yySbkKgROljYnm9yWkORFrjim2UcdwaB0OBSiH0JwtRkN8aP4D1g8aQHwGLKA4N6bQxosYtmnUs
xFLV0PlwBqfnTPCTIHTTYx7FoHDT/g/ssBqMjJXLplwr5uCRXWgoVP59JEe6Y+FSILElw726fI2H
M7kHTQYyaonwGQxAvTNX6vAaLaxIllAiQ0tDGwdmo/HwZGF4FxWrTAFdReKm8/0qEms0rpZ77k19
9BQLXUSx7I9i+WyiixHjaaOQbrU8bUS1mKU1F4Clk8rrCvAy07ZKS3iqXU2atNmjjLa+Tt/Cx3nH
N6gIep8KGxGdTdqpZkxCo+hwcXBh1Y4hdXT7XM5edNXvJhMnAoTRE4+DulsfeKhtwoeJKmgWwvJh
orB0HrVwTRR00K6HImIUfG8Y3Wm9UHqgilLlJYO+A2vngMlxEOXqM0wE+gBnWzOyRk8FSZZ0Ec6u
47rd9syW8bsap2/1mhrv0/BOjRiv3fVCtg0Hyu2WrikUpybktWinHBclQnUyqnZT64Ze0V45YdCu
42Qp624xTM3SpWFp7y8TW9oZCl8a8oS+M/2K5QzeLIqGfGXtxkXtN+gBq1m18eC0a/EAfE0+vXZl
OdGZINZrU7aluyWrB+LuFi+KjfU1u8d4ZsLX+IHXqHIJwvZs9i5tmkVWooe6nhl8i1E7vEZu7nPe
qxo+VsU0870iSHeGgOp9sOTeB0vQIBbc0/2fI73zTGduJdln+EMtPRsJuvh996kmyrVCdRyEuHss
pZHbRU6Karh3GZxMAkvQxu+H+kS+IoLv4A1DeOduUzyKWmwrPdu+5qqT/r7tqwcTPzEClUMdrzO1
cQZDkndtuWsUBFF0ZHkRD1HZB7mfNIGldJBn0wZwDypS4bS3pf9o5SrQrKtx5B1ofoblIyg3H/aO
7wkKBXx3uIEe9TP1mF5PXAxsEPp/J4TW65mLhsWJvon460JAgU7c6rm3qi3+T+F5Evm4zfxNV2Gu
D93Pcst65G595C4NpbFBzn8dGSpPyPb6OhVNQ1kUjZKqEREvWdM5tW2gzYcPLnsSrhxpkFDurrTY
jHI+MMx0SQP519aC+TIP/9haeM3Dx/jBPBwx/uo67drQkney5Q31rq1LSueo2m/r3KbhvE/T1PTI
KdZDy/ikluEObtMcze4BBGUteq06iqdvliZY6qWflkbxARqYmuxo3oTy8rEC8fQ3FJoU/dn2HZ3S
/X3+7RbMrxr2dQvPPu1766vxtUOtxlcOqnsbzvLhO56F/DWgfZvOmtd49m04S4tqPPs2nAX2KENN
ScWMr+YGnGN5NsVz6sf0gpFCqd89Pu2d79eGlO31aYw8lSyi9LGy9aNUmPdcvKKhjN5ofJfOGzI9
WvusLp7wsbpunlx1j7DEQtzN8tfi2n5uFCZGU98BGJpP9RPet9wUioDQOs+O291v01OG/XPD7e/2
/u/SqFkN1SB5NLZ5NuQwmUkNUFfTsxLnTiRRWPt5sEE9tqrv99jlhlc+63kvcfO3MXlK3H6JbH4e
FXgr8ns689PZu/0zfjE/J5hv/HLXfp/HqnhNiFUz1awNSuhU/h+iMe1KXBdKQlnmdff1EYnSlYG6
BG/qHWqPT2ZhIffmth44I66e/HhrHv+1zjNT3OcDnwMxElYF9+JaIDfJh2c9glM0uqp6bA2+o6/d
MhKrxcmG3/RnbqtGS0yClSVZlNAuuM6vW7Z3A/lLmsy/lydQ8nzUULiFaFRDftwaQn9irrxO/JSQ
kK+oGK+2RXqREJduEV1g/mw9zUYa/FdQj+NjvA9xFEn+fp+u0M4sVhfBJEmvLUWNW6eer9CU4ltk
zU9DC9E88+tRXupsvLjvSdsraZJ0Ms3YdZ9Q3nqktmSY++7MUBhVT88lN3EcSY0G8eE83d2cidWW
9AzKZnvVsPk7jPkTiDG/s3N+z3JBW+F/2kC7YNoY+4Bt86t++gLbP/vJDZlfVs1dzQcN/hw274ke
5ISlHux8HqlSNX0BTm1avUHnfdwjvXA9RfMVO3/28ys9/2hd5nfo+Vch+jVBfsbo5xn7AC7z6zv6
PTA2f9rlP2ty89td/jc1dGcj/7yGniiYX/fH7/38LzgRQURMEdx/ARWxjSZjJJkxAAABhWlDQ1BJ
Q0MgcHJvZmlsZQAAeJx9kT1Iw0AcxV9TS0UqDnYQUclQnSyIigguWoUiVAi1QqsOJpd+QZOGJMXF
UXAtOPixWHVwcdbVwVUQBD9AHJ2cFF2kxP8lhRaxHhz34929x907QKiVmGZ1jAGabpvJeExMZ1bF
4CuCGEQAMxiSmWXMSVICbcfXPXx8vYvyrPbn/hzdatZigE8knmWGaRNvEE9t2gbnfeIwK8gq8Tnx
qEkXJH7kuuLxG+e8ywLPDJup5DxxmFjMt7DSwqxgasSTxBFV0ylfSHusct7irJUqrHFP/sJQVl9Z
5jrNAcSxiCVIEKGggiJKsBGlVSfFQpL2Y238/a5fIpdCriIYORZQhgbZ9YP/we9urdzEuJcUigGB
F8f5GAaCu0C96jjfx45TPwH8z8CV3vSXa8D0J+nVphY5Anq2gYvrpqbsAZc7QN+TIZuyK/lpCrkc
8H5G35QBem+BrjWvt8Y+Th+AFHWVuAEODoGRPGWvt3l3Z2tv/55p9PcDtBdywfp0l/sAAAAGYktH
RACfAIEAdvOErgAAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfmBx0GLA+xmQvgAAADc0lE
QVRo3u2awUsVQRzHP/PeU1ELNBM0k0AhCNKIglTomBF0y7pG1qGbduhUqX9AdepSKXhTjOoQFlEQ
SV46hFGHrAjKikjNwLpUvOkyCz+HeW/X9e22yQ78cJnfb77z++zOzsybVWmtWc8lwzovKWAKmAKm
gJGWXJydKaVuAAo4quNan7TWkRtQDtwCtLHbQHksfccAtwF4IOA8ewhsjLp/FeVIUUptBu4AHQVC
ngKHtdbzVruzQJeo+gmc1lr/SMwQBZqBF9ZTe29M1r0Etllt7zqe+KbEDFFgO/DWSvCNgW4219L3
Dtgh2k9Y/m9AbSIAgd3AZyvBGaBJxDSZOhnzBdibaEBgv0lGJvcEqDP+HJAz13XAtBX7HdgDXE0c
IHDITAYysXtAtfFXAo+MVZq6ahNjw7xOFCBwDPhtJTUhnlYNMCV8j4Ea8VQnHJNKMgCBU46EhoW/
EXjmiHkGNIq44cQBAmccyVwW/lZgtkjir4AWEX+5CGBNrIDAoCORQeFvA+Z8hp42MW0+uktARWyA
wCVHEv3C3wnMB4DzbB7oFO37LX8euBILIHDdkWCv8HcDy6uA82wZOCB0eh0xo5EBAllg3OrwF9Aj
Yo6YOh3SfgFHhF6PQ+8mUFZSQLOGTVod/QC6RcyJNYDZdkLoHjR9Sf99b31dM6BjDdPAgvXO9JUQ
zrM+od9l+pT+6SAbcD+4BscaNge0i5iBCOA8uyD6aXfMyjPAllCAQItZp6TgLNAqYi5GCOfZRZ91
9bXMKRAgsBP44LPzuBYDnGfXfHZGK9bSooDAPuCrJTAl9o6u2TQOGweyJodax7wwD3QUBTQ/VWzh
SbH7d82mcVqQXPYUA6wHRkXwmLhrDY679i9sCmgQo2lMbgSA+iDv4BAwEmDv+a9swMptBBhyseQK
HEQNOaqzCTqwzlr5nizFyXZeXP8Bnpu/Ls1dQvsj8KmA5lZzPrNazXzJjw3NsPWGyCJQVSCuyvi9
2HNFNM+H1BwMmnfYjy8KKCvgKzP+IEM7G1Izlq9LmYD1yudGhdFMP5+lgP5zk14q4FgyE0ESNEN/
AK1QSh03B712qQYqEqIZGrDabItKWaLQTN/BFDAF/I8AMxH0kYk679V0kA+ZTD5mzZV7waD/ZaGU
qgM2h0hmQWu9GJdmaMB0kkkBU8AUMAV0lL9MuCuGda9xQQAAAABJRU5ErkJggg=="
    />
  );
}

export default ImgHouse2;
