import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import { StateContext, DispatchContext } from "../Context";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { ButtonWide } from "../components/Button";

export default function BatteryPage(props) {
  const { battery } = React.useContext(StateContext);
  const { setBattery } = React.useContext(DispatchContext);
  const valid = battery.haveAlreadyYes || battery.haveAlreadyNo;
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Batterie"
      canGoNext={valid}
      {...props}
    >
      <BodyLeft>
        <BodyLeft.Title>Hast du bereits eine Zweitbatterie?</BodyLeft.Title>
      </BodyLeft>
      <BodyRight>
        <div className="flex flex-col items-center">
          <Button
            active={!!battery.haveAlreadyYes}
            onClick={() => {
              setBattery({
                haveAlreadyYes: !battery.haveAlreadyYes,
                haveAlreadyNo: false,
                type: battery.type,
              });
              props.next();
            }}
            icon={CheckIcon}
            title="Ja, Zweitbatterie vorhanden"
          />
          <Button
            active={!!battery.haveAlreadyNo}
            onClick={() => {
              setBattery({
                haveAlreadyYes: false,
                haveAlreadyNo: !battery.haveAlreadyNo,
                type: battery.type,
              });
              props.next();
            }}
            icon={XIcon}
            title="Nein, noch keine Zweitbatterie vorhanden"
          />
        </div>
      </BodyRight>
    </BaseScopeRow>
  );
}

function Button({ active, onClick, icon: Icon, title }) {
  return (
    <ButtonWide
      active={active}
      className="justify-start"
      onClick={onClick}
    >
      <Icon
        className={`h-12 w-12 m-4 ${
          active
            ? "text-sun-yellow"
            : "text-silver-light group-hover:text-midnight"
        }`}
      />
      <div
        className={`text-midnight ${
          active ? "group-hover:text-sun-yellow" : ""
        }`}
      >
        {title}
      </div>
    </ButtonWide>
  );
}
