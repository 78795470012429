import * as React from "react";

const SvgRoofFelt = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.5 10 20 6h-5l-4.5 6m9.5 6.5H6L7 17M18 9.5h2M22 16h-5M21.5 12l-4 6.5M17 12h4.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M4 12s-2 0-2 2 2 2 2 2M13 12s2 0 2 2-2 2-2 2M13 12s-2 0-2 2 2 2 2 2M13 12H4m9 4H4"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgRoofFelt;
