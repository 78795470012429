import { BaseScopeRow } from "./Base";

export default function NotFound({ next: goNext }) {
  return (
    <BaseScopeRow>
      <div className="flex-grow bg-primary-gray p-8">
        <h1 className="font-bold text-3xl">Not found (Not implemented)</h1>
        <span className="underline cursor-pointer" onClick={goNext}>
          Back
        </span>
      </div>
    </BaseScopeRow>
  );
}
