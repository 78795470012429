import * as React from "react";

const SvgCoffeeMaker = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.125 8V6H22V4H8.875C7.844 4 7 4.9 7 6v16c0 1.1.844 2 1.875 2H22v-2h-3.778a4.898 4.898 0 0 0 1.399-1.758c.33-.695.503-1.463.504-2.242v-5H10.75v5c0 1.64.76 3.09 1.903 4H8.875V6h1.875v2c0 .55.422 1 .938 1h7.5c.515 0 .937-.45.937-1Zm-7.5 10v-3h5.625v3c0 1.65-1.266 3-2.813 3-1.546 0-2.812-1.35-2.812-3Z"
      fill="currentColor"
    />
    <path
      d="M15.167 11.667a1.167 1.167 0 1 0 0-2.334 1.167 1.167 0 0 0 0 2.334Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCoffeeMaker;
