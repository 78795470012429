import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import * as icons from "../icons";
import { StateContext, DispatchContext } from "../Context";
import { ButtonIconWide } from "../components/Button";

const availableSizes = [
  {
    size: '2m\u00B2',
    icon: icons.Size1M2,
    value: '2',
  },
  {
    size: '3.5m\u00B2',
    icon: icons.Size2M2,
    value: '3.5',
  },
  {
    size: 'mehr als 3.5m\u00B2',
    icon: icons.Size3M2,
    value: '25',
  },
];

function SizeSelect({ next }) {
  const { setBalcony } = React.useContext(DispatchContext);
  const { balcony } = React.useContext(StateContext);
  return (
    <div className="w-auto lg:max-w-[600px] flex flex-col mx-auto items-center">
      {availableSizes.map(({ size, value, icon: Icon }, index) => (
        <ButtonIconWide
          key={index}
          icon={Icon}
          active={balcony === value}
          onClick={() => {
            setBalcony(value);
            next();
          }}
        >
          {size}
        </ButtonIconWide>
      ))}
    </div>
  );
}

function BalconyPage(props) {
  const { balcony } = React.useContext(StateContext);
  return (
    <BaseScopeRow
      scopeTitle="Deine Angaben: Fläche"
      canGoNext={balcony !== undefined}
      {...props}
    >
      <BodyLeft className="bg-white">
        <BodyLeft.Title>
          Wie viel Platz hast du für das Solarmodul zu Verfügung?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Wähle deine passende Fläche aus.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight className="bg-white">
        <SizeSelect {...props} />
      </BodyRight>
    </BaseScopeRow>
  );
}

export default BalconyPage;
