import * as React from "react";

const SvgMenu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="menu_svg__h-6 menu_svg__w-6"
    fill="none"
    viewBox="2 2 20 20"
    stroke="currentColor"
    strokeWidth={2}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
);

export default SvgMenu;
