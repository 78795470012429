import * as React from "react";

const SvgSmartphone = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8 19.867h4.4m-8.067 4.4h11.733c.81 0 1.467-.657 1.467-1.467V5.2c0-.81-.656-1.467-1.466-1.467H8.132c-.81 0-1.466.657-1.466 1.467v17.6c0 .81.656 1.467 1.466 1.467Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);

export default SvgSmartphone;
