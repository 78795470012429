import * as React from "react";

function ImgGears(props) {
  return (
    <img
      width={28}
      height={28}
      alt="icon"
      {...props}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAWY3pUWHRSYXcgcHJvZmlsZSB0eXBl
IGV4aWYAAHjarZpXcuQ6tGX/MYoeArwZDmxEz+ANv9cGM1VS+br9SlFSimKS4DHbHKTZ//N/j/k/
/MvBZxNTqbnlbPkXW2y+86La51+7352N9/v9F95/c1+Pm48/eA4Fnfn8Wvrr/M7x9O0NH9cZX4+b
+vqLr68LuY8LPyvQnfV6fV4kx/1z3MXXhdp+XuRWy+eljteF5uvEu5TX//jt8e4//W6+HChEaSVu
FLzfwQV7v9dnBUH/fej8LHz3oXEeMbuvk+FHDPl1MQLy5fHeP639HKAvQX6/Mt9H/+PVd8H3/XU8
fBfL/IoRL376B5e+Ox4+buO/lMPHivzXP5C88sPjvP6fs+o5+3m6HjMRza+KusF278tw4iDk4b4t
81X4n3hd7lfjq9puJylfdtrB13TNebJyjItuue6O2/fndJMlRr994af304d7rIbim59kyZEcvtzx
JbSwyJoP028TAof9x1rcvW+795uucuflONU7LuZu+n/xZX73x3/5MudMhcgpyq9YsS6vumYZypy+
cxYJceeVt3QD/P56pd9+qh+VauQ0hbnygN2O5xIjuW+1FW6eA+clfj4t5ExZrwsQIu6dWIwLZMBm
F5LLzhbvi3PEsZKgzsp9iH6QAZeSXyzSxxCyN8VXr3vznuLuuT757HUYbCIRKWR6q5KhTrJiTNRP
iZUa6imkmFLKqaRqUks9hxxzyjmXLJDrJZRYUsmllFpa6TXUWFPNtdRaW+3NtwAGppZbabW11rs3
nRt1rtU5v3Nk+BFGHGnkUUYdbfRJ+cw408yzzDrb7MuvsICJlVdZdbXVtzMbpNhxp5132XW33Q+1
dsKJJ518yqmnnf6RtVdWf/j6h6y5V9b8zZTOKx9Z46gp5X0JJzhJyhkZ89GR8aIMUNBeObPVxeiV
OeXMNk9TJM8ik3JjllPGSGHczqfjPnL3LXN/lTeT6l/lzf8pc0ap+9/InCF1P+btJ1lb4rl5M/Z0
oWJqA93HOd1Xw39r+fbHnzuRv0YQQZ+xnVsxh2FXCSmd4ZNZsZGBlsKex8/WooelCHMnRimcBpmW
5CD2dnR3IGydql8ALx3ZxGmWc4zIt4ezXcmHOJzTXeW4j30HnVjXuvydXNocF5CMUbbjF+CWU5pP
xd0LzbzrfUta41iis2sWADU/7vFNBq3b9bUsjnxb1kVV+CA2c9fFooSpYftWQOcZS5/R9bgpnUmc
/ellrZ0m8U+thZJX8rO0TFlT0G3HxYoo36goSBD895/mp39ItYRTl/JDKTm/xpqlthIpV37OToZm
K8f5IUkAolXjVwMa0QqJQM9EfvvuaatCOjVGfabpcgvx+MUfxvBr0WW+Jl/3am6kFjdZNmGcvsqk
u0amdfIgKuvk3oodjgAVrSjNWIerHsQNg860kGygwE8KysPg9oYIB8tyqWRy4HTXTL2BSm7a06Mv
jQgXYAPMmDwyxJpnoPDaaXWUskMnm8Wc2sJMSk4doE7d56Q55hlprVjADgqXmNQjgGIpTndL9Wxw
+nwKrPkU4S7wGDQuNRJSrSHl5fNWzcM1eR+/06r9sAzPiqZo6SDXdky5gEdPtX3UrOflR83mpwDz
HuiQ7VRiuw4WSSZWPETlEJtGSkwlGQOJ4ncnt2sNEKLWSG3lNeuaabRZ+iEOczStvCz6jN+m7r0I
6tNzprNmNRdnbJ+flhiJU0/NI/lGern9JqvUgZ9kdZH1GMglvxFCO3meWo1rPu7gCyCFWuvphsza
f/5pnhcOGMsrdjTSiKSfVtoO9K2eJ91x1OYyrD4cEO2hldlsCNXtDjSR0TqC2SEtYlja9p2UU2Gj
+r2pPso/jpITEQTe/XQE0KtiCb6bpZzUKddTgQjQztBOHYQd29MUguA+QN2+Vy9zWSrujDB78SAU
GGXXFHCF0biEaztrQV2+QVB7X3z7OTa5twTbg4ej0HdkmyoFcIh/zK1TyHGOXAgvctB7kY+h5O/v
Q993Ue+gJ1PhWW0/NS7WX0/g/SPFxUOfuCrZgu4ppZa3MJkHN6gcPXn//OT9QUaY99ii8qTgnHTx
6LdwqZNBMIGBwjddsC9z4lhhL9VELkCoW5DYYJl5WkpDGW2Iw0CjwCdh26mHX7fia4RS7qVPNJ6C
5q5k6inL2i4VvBYjd/Ush3U8C2I5uqCHo8ZQ5k92uXSTy0TB5Ty54/JWBFsPvYFCdhTsckKQjDCI
BXcEDIGchRVQg9DUsVtsl8s2jtiuDSuLAEuYLYOukO6J0AZXGZ1AILj5FfQohfO28ntAM269qbVw
XCmmFkgcBLJ1F7CTPi+beyzkDzDKwnZEboosae5MePw8Np86wYBFVRBVD/ckc2iMNWJCLqxK/qvz
OwPQLSMAbICkwbsL7wu8taHNBrSSWZ4p0OeNppppdYNpRATwdAWoXxmpQo+fDW4haIA7WGILsCjS
wBOt+gt9YH4tHKBsbvgBmdvNDK8MGAWKzmXsSkQ/UBPpB6R3il+kZKEralZyA3aqbR0ySxiQYNJy
NewyIiEtUeV3CcbHvD1kD4wsMYLfkRxG+jCs0gWEjVgUMKRTM2EhBFcaEEw9BBTKGWBkK5kypL+7
i6YgXa1T7VYHGP4Ctgr6YlOk4DhNCFlRoMMm2gnJgdg7wdwCh4jdRyVTkvOeyLf7V1B2gyWkBjNP
CSJqEqtxKEYqb6A08slmZb2Hq2PvKloVSh5oo7hX4DGdEki75TBzApGXHzEspNcgI6uABCMDYWtP
Az1QJGU0GmOlBlfI8uy9oCueIzerUvWhC8QyS70yyj1q7Qop6AoUMjwPSorSPuSOkhbmblKEHBrS
G1MvAc5JH1mPOwZyPAbZCr5qD4Pak6YzY5cJDcF6fQpmQW7cENhEUkck11yjQV7INp5/9NPG4I3Q
bCJQC31SJ6q3mwT4TmCFVVErAOqYOARC3iEJyiJkEGjL5AMtReZw6iH5+ymUWhyvUuNCM6atR+U4
eJwhww2CZrUW8gd5lWdCVf+02Mi0gx927ObkoTxLIyjVPYIqyEzkfFaHgHjUzRBQ1ApgK5iDoKGx
icFqAwxrUB5C6xW74FpAUAA4CAwxHtgvwhOTi/A8TdH23nBmEuHVnOExesdSYECdLtTrlAnO4L4A
db+pibYklzaplyz6ER+8ThqSg4LCjHrDvniVJXgEjODQ0vHdu2YBVyQdRhqsJ6Ql9ov45/zRZJiv
B0IjHF2wrGYAhpbVAjqgC+Uhb0HX0UETkD9YWnauBu22CEHaSgFI/hSVzhUSIoTU9QwgL+rFARgD
PEZeaDxXoY+WFYUGVTkUKXBg0hGXKWM+Qqjn6esuP0AW6OtWf/NIqLWH4bJBfT5sqj4552mjq2/f
LU930DLjtnw+NIWIaXfKA8+Hw0Z6p4jvb0JtS8tTwyyu0g1TmpVYfYMh/MYbiF4wpJbtSvPhHchi
Q7Uhhi2wrHqL6HdEMDWMigdfIZWKzhuN5WQNMw/qEuBGpIMzCBt5IGioBBOJ7U51RLsCXpQYA0kt
cTG0IuwdUqc2poI2KDf5DVyp86VOuzHJNLEoPhoQKAbZPXJ3wJ+XPNgXtumUiw7p0Te5UZz+wc06
fQEHAhAhmxHAbB/kdeYYOfEgvmoWF1gK8BDQ17nqKsfCphWApDAKFhWemwj6ipFD9+LSDQK7dTtq
OoE+Qzegd1clNZoZYLQlFI+IFkiF6SmoApxJajVRucgOicWjWU3G7L6T6nmfZwkr0ahQAjJBZCQo
22eL7j+lwbN4qldEiGAxs74zMWVF56s9ML+VVihX7kEOV+2BzVftkUPSYwtIVdFlM+CnDVUNDQAc
S8EGLxPhxrZcaiI3m+LYPylpHv+IxKiWCqljs1b1/ZYcssJRgoHA8PCE/DgczNpSUKSLXoIN2slR
0SVVjwErLUi+VB7N1UjxxjstgJdRckLHDuBq7sFtqWlpieQ8NoNC9AEQgn7jtscH8rCpQoOxrD9q
7Z/9PJfDKNIhn7/z064ZJ33dEUthrWiq+1QoBMIzMZXrziEkTNzl9yZ+tD+7z1XKx9iVB5Ff6LKZ
W8FDqlf5M2BFkvIiOgl9BWPAUEAnNQWJzOSk2EN+o5fh9ELzv+ELGCClF3SlfpMXoV34ilRWpqQp
blwrlKqyaahg6oNmMZQ1lYHZdw1Gg/0rCA6JEozeKRB6VG0iZQ0ncoEEcFSXU8eq2D5sJgEzFgMj
8mwZ/Eo8dPTy3LylrzN4RlxJm8eD2FoYImZj3oO/Fg9CQY9en9VzNh3BF4UgXBbmpy8b0q7QeZtI
SaXc2WvDh4II9OJp0R8Ah7IvEDMdwzeMH8oNyYRDcwTrU5BzakmgiRyhYOvc6JB6CsRl84d3GkHe
6QbLPNFaZPBLtHool0QBDYtr/MKhH+6J53rwHiVgrn2a+WWfaF1ZtkflPEOOlvGqA9sBq3V7QJxB
rWDYwSdELdI+Up4w7T4lX3qNcq1TGiLgGsv1+HSlK0KQIzAD7NFbCc02Ff5B77iBWfJQp0EA0S3T
TSn8rbG3bL7bv2BmgIGstzcPgpLnUPAjmafv1SXO/hX97PrTZjS/6M4vvcmhpzc1nPvSmrDyqznN
7c5Xb967/tCdGw5wobyFBaGCg0FOKAsNoFRjmJZBfK3kbmcggZCXc97OCCAnCmkUYDIBggk/Bbmx
Hl66bEkd8AmgJ+0BTmfQH0Fi8NYccNbvZIq6dvn29aKEWnagPCzXNwVW68SGbPC5CBIpf7SlNbtf
J6a5btxagZQfyVjYFeKPkQL5jowAxKsh6ohcBVGcYtVQPxfeThcahzXtNHev74FJ1WSXumGpaLre
0tIEAgACDjACeIqy2pTfDdETEedtnt5gER1JoOUoOjnkd/FrZHuLf5db+/lV+0KUdfrL0om5Qmf9
5jMHR2IEUOBII9xVBxHLnzkY4WI1gt9FakTjqrX4s/QpeITTSWfJ8wJWa9E4rBjsxgwjONDpGIqr
T7ssx2zuLdG/KnTzSaJ3zpMaweuQH2253RPxY6SdB/ztYMt8bxF/2RxkgRskTUc0tk2ah4J3nRsB
nNgsbCESvsaWbOphARkxupQt9h2OABbs/dIux8XXtH66KvPDMt3+0kO/aqEz0CqySf2ZrBntfAlp
cSJraiJb7MAwIyx27zPxOKiRvVN25JWECv3dM5NeVb6C1WtMaYR26HsQek8o3/FbhMY74i/ebewM
1i5tyOf3QzmwQPwmJEUk4OEpTUOI0ZMRWxXvrAu0A6Jl1PJBn2k/JoHplQMeOajtEiB7Y6y1K0C+
KYW17ubBwbnQbI14uKRpYhyYK/TTUksUUgBdzZE1ypkDIw4+gIR+5Ehf4wcnmTtGBYoRoYZp3ivw
G49J3ULj0TZAlN+wIVhKC4NDCTJrQu2BWSCbcvV0vVaEsK79ji5QaNz1Kkfsc0SRjltWEhNF0+DD
Aai+xCgPT6qDxPvCV5qlSfbhiasLF1vV6uSbZNy67hrYaKL2S9h/UN9wW7iraDBaVRaoNcwsgh8G
BnoQm9C+PQW17NOOw3mQCCHuCyDBklAaTdN6UyYWG8cdMST9SntuUjUV9BT6AwUVGTHSGMTkjt8w
136mdqGA9OisYZKm606rn8h8DTgqcgJlQaWIyPPSAFvz8UbNs6yNQLbap/GOzjkTTJkFGKEbbfgL
BanpSuS6584M83BW8624JnC5T7fmbgmNoYDTCP7rbGuexyaCJxWCc9aj/rlzBnKFNEgjlbJmimZH
yCBJ51PCbdAmtAb8ztJTyVtDKmzAIAJqMlue5HZ3nuRqTwhvFZLROP381aNRA+dxvxMviDjRRyQS
Igv+yQRbkMaKtKOEgQN0M7fHIUEeQC7h0NDxbrpgQgKt6GLBq6PWOoHGiFQWuLeZFxDLozcjbUdQ
01jao3+CGrTJRvLPE9RFAUROeGJqtfnjFVOjklFQH2yma8EiupYbqmuTcJTkYJiTRCIrLXHP3pGF
vm8kbcp07ajbLHVtvmWN+huagNxdENoyRBURKUBLYjr0ORHqB22M42zaP/ECqqK9wbwNxdCyJstv
TYoUC2F499KkWdvEOz6aFL9Id1SJnH67zsomTm1bkjWkHsd49/VoLl0IxD8KTN8bNQJTYel7o+aN
pc9GjbDUVPts1LCiuZ+7zB3uhpCkj8buYGDWSC+lJ/mymb1oC78mAGVp128hRiFHHHuIMOngHDfR
RfxDu+DKMjZdSQHDIdKt+e6jwjQSRG8884c7GCfRfslPRWhZ8y6gOoe5gUJy7bsm2NpBrTLm2v7T
RqXkc9hdA5MXVhhNCckXkE87bvLTCcMYvEMfD5g0Ybq7Y0VTwiJ0gLyn15bhHSR0p2p9do5hrKUd
0STqxshSCiDbF6fOauFjje7Honp8guqgQLwYWVwoIEwNYDJwseVQg21tzUZ5TRnyL7a6NFTyrjl9
ikc+IEztk1hNftP+qwn7h0bvZO4680dgdCkwuhXFsRw6Vpt/6ZgoZhfdC9ExdUfjrPbAvgaLVln6
i48DmAtcibjMI5MqpUf85XNJ+3W4HkjGvQD/+rTEXjgE7EwoTtHSR3bw82GaTCVKby6s3MCjWkxc
l339ZHXptPTJ6vb2ndXVvMGoBRIX0O414aNhyfE8r2lh82kG7Qn2Oy3ceyACUooaqgZtCdx57dVH
ynkd0Myoi7c4QecIIMoYl65xUSyPd6EJKpAO2MqxF2653x9c0CTLqKSefSws8Xp2ANbVlP+2OfrW
kHmro4aebhEPai3f4AtHPIBAC1JKNfZvw21/3V2DLhA3y/x+m4L1Nfvab3sUPNr7KvjR891re0+G
TY+bN+CiWAKPhn0Ke++ZS15xR3zrlgGvd76X8CYVZSp8I2cAqzTjs8Vu/u3TD3dDN6T3JqeMei3X
uxntcr6MetbkHM3wMuoN81s0SMOoA25wDjWW9Ekh5/czmOnrNd2WGtHQoD6PQAnfyYymS8HdyUzM
ZYl9159Wa35zwtVf1v5Ugf2gv8z5kF/6KOQVYDLdMMD6py1281nzn/+wPf/9Nj2cSM8Bi0ulAKBn
8Bkru9O1strXgYhQKhKFPEFtmtfB2VUAeTZCC3EMTFXi39o1bzW1WTaumMTEBvu0URseuev+7QFI
zFtx5b3bTVfh11L72O5OXjvnWVvTz3Z3zMl2CW3tdvs2WcHd7d7nB3wzf/l5qGcf+zenmB//QGrp
JXc39+9MD7skYdBplI7DxNX3hpYYnv4GRzvYN7uplTfly9uR2GhGQaMufz9ocv64PY7Qf7bY77Qm
vrbYKb5nk70+l+vK4k1mvNt3ZAnbTTLLQCAVjV3HyRQiYsfQyQttH5q9HyzuyLKEJD+23XljU2fe
TzS83OFjlQot+7jDa5W6p2llD69XctceotIq5dAoBrhsNV5DJ/AH5kU3tDGjudLUx00+b92b9Dfz
3j/Og6mjv37PeNTTIiPPhOoJPOKNFWnvKKZPs+NQHx2NuYYapMd9EejWfofqv7mb+a+P8q6P96c5
zC8+zlG//xTGTz6EoQvW9wXNHz4f8tcXNN8v8b9e0Hy/xP96QfOrZ/7XC5pfBvEPdaOyeT4jVjH5
gaaVcF/PdqHVJ0HuRwVC0lhMvijvujSEODI4qCZJq9BwxRxzqbY4sgpPH/ZC6UtlYjDA7vJfu8T8
/7bZf74QsW/m/wEFVye3CXjDWwAAAYVpQ0NQSUNDIHByb2ZpbGUAAHicfZE9SMNAHMVfU0tFKg52
EFHJUJ0siIoILlqFIlQItUKrDiaXfkGThiTFxVFwLTj4sVh1cHHW1cFVEAQ/QBydnBRdpMT/JYUW
sR4c9+PdvcfdO0ColZhmdYwBmm6byXhMTGdWxeArghhEADMYkpllzElSAm3H1z18fL2L8qz25/4c
3WrWYoBPJJ5lhmkTbxBPbdoG533iMCvIKvE58ahJFyR+5Lri8RvnvMsCzwybqeQ8cZhYzLew0sKs
YGrEk8QRVdMpX0h7rHLe4qyVKqxxT/7CUFZfWeY6zQHEsYglSBChoIIiSrARpVUnxUKS9mNt/P2u
XyKXQq4iGDkWUIYG2fWD/8Hvbq3cxLiXFIoBgRfH+RgGgrtAveo438eOUz8B/M/Ald70l2vA9Cfp
1aYWOQJ6toGL66am7AGXO0DfkyGbsiv5aQq5HPB+Rt+UAXpvga41r7fGPk4fgBR1lbgBDg6BkTxl
r7d5d2drb/+eafT3A7QXcsH6dJf7AAAABmJLR0QAnwCBAHbzhK4AAAAACXBIWXMAAAsTAAALEwEA
mpwYAAAAB3RJTUUH5gcdBhkuX8HZCAAABBhJREFUaN7tmluIlVUUx39rnNHjTIzShZxRFIJEulgN
5f32UC+ChQ8lRqX0UAm9SEgGUkJkSFGkPggTGb0MoiFKQhdBkoGcQbz0UBaNIxZqF9RKSWfGWT20
hM3uO2f2N2d/55y+zoLNOefba69v//dee132OqKq5JkayDnlHmBjbIEi0gx0AA8C04FJQAswCFwA
+oHjwBFV/TFzhKoapQGzge3AGUAD2hXgM+BpoBBrHv+aVwRgM4E9gaCKtZPAqpoDCLwCXAsEMWi7
O1SC5xNgStUBAuOB3Sl3aQ1QAF4age8nYE7VABq4gwGATgFvAz322Wjjm4A3gC+ADUBfwtjLwMJq
AdwXAG4A6AiUNwe4niDjEjCjXIANKV3ARmBZAGsTcFug2LuK+OMJwG4RGV8RRy8iDwCvpVkPb/xS
EXlVRB7x+IZKyLgbeL0ifhD4MtCYXLQz1+CMXecbHKdvHPCuqWSSvOvlqGoouCUprOXL3tgC8IvH
cxoY4/G9V0LmR1mfwRdTKEWb93vYjI5LAzZxl24uIfNxEWnLREWBW4A/U/q8g0CLI2O15/CfcPpu
BboDZL6QiYoCj40y/FrhyekAngHu9Z5vCJS3dzQAQ7KJuaNQjCHgG09TjgJHE3j7AmXeJyJjVXUg
tpuYkUJeL/CpfZ9q7qG9iNtpta+hfq7dWvQz+FWgCp0FmmxMl5Ml3DiTBeubDBwCzgP7Rwi+/fZQ
FiraXEINNwHnLBrpVNVB61sDfG8GajPQCWwUkS5gPbDQ+Jam3I9CFjt4vMhqdqcIEtaXmS/eaAuy
8INXizyfKSK3B67j4kgXEM9nEYteKPH8smM0ponIFhHZISI+oNORAD4lIvNjq+i2BFXZ76ZDFoX0
O/3DbtJq/W8Bf0VQ097Yjn51wkvaPZ7lCTxbE2R9F+ksrop5BrttR1x6UkTGOr/PJ4w74/m9+0eI
N9PQmyJyU7R0ya4d/FXc46VE7zh9B4BWp+9Zi0E1YtsUM116LuEFw8Bkj+8eYJb3TIATkcGpWfc7
YwFssUjFfcHhFH7w/QwAKvBhlHxQVa8kXFdME5F5IjJFRGYlxJp3iEibiExMGc+modlRr+4thnRX
8Jr5QgW6HL615hIuee4jduscac6Spj4oIlOBYyWs4VpT5U6gNYMd63OCix5gnar+EbX4AiyyA65V
aPMyvRe1BTkEPGrXGJUmqUgBVFU/Bx6uAsiGigA0kL3A77ktYVslt9Il8N/yXKPvB37IM8APnCuR
3AH81uoX5BHgSWCZhYu5Aviz7dpcVe0brZDGGgGzE/gYGMM/5bJfgROqerFcwbUCsEdVd9WUH4xM
zTXn6P8rVAf4fwYowMRI8yhkBbAcK3oVWEl4fa/UQn2dWQJZ/0tzHWAdYB1glvQ3fQGLzLVIxy8A
AAAASUVORK5CYII="
    />
  );
}

export default ImgGears;
