import * as React from "react";

const SvgCampervan = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.333 16C5.067 16 4 17.067 4 18.333c0 1.267 1.067 2.334 2.333 2.334 1.267 0 2.334-1.067 2.334-2.334C8.667 17.067 7.6 16 6.333 16Zm0 3.333c-.533 0-1-.466-1-1 0-.533.467-1 1-1 .534 0 1 .467 1 1 0 .534-.466 1-1 1ZM15.666 16c-1.266 0-2.333 1.067-2.333 2.333 0 1.267 1 2.334 2.333 2.334 1.267 0 2.334-1.067 2.334-2.334C18 17.067 16.933 16 15.666 16Zm0 3.333c-.533 0-1-.466-1-1 0-.533.467-1 1-1 .534 0 1 .467 1 1 0 .534-.466 1-1 1Z"
      fill="currentColor"
    />
    <path
      d="M22 13.4v-.067h-.067L19.6 9.667c1.066-.6 1.733-1.667 1.733-2.8v-.4A3.13 3.13 0 0 0 18.2 3.333h-5.667c-1.267 0-2.467.8-2.933 2H3.333c-1.133 0-2 .867-2 2v11.334h1.333V7.333c0-.4.267-.666.667-.666H10.6l.066-.534c.2-.866.934-1.466 1.8-1.466h5.667c1.067 0 1.867.8 1.867 1.8V6.8c0 1-.8 1.8-1.8 1.8H16v6h5.2l.133.2v1.867c0 .4-.267.666-.667.666h-1.333v1.334h1.333c1.134 0 2-.867 2-2v-2.2L22 13.4Zm-4.667-.067V10h1l2.067 3.333h-3.067Z"
      fill="currentColor"
    />
    <path
      d="M12.667 6H18v1.333h-5.333V6ZM13.333 14.667h1.334v-6H10v10h1.333V10h2v4.667ZM4 13.333h4.667V8.667H4v4.666ZM5.333 10h2v2h-2v-2Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCampervan;
