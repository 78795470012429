import { useState, useEffect } from "react";
import ModalBase from "./ModalBase";

const required = (value) => (value ? undefined : "required");
const numberWith2digits1dp = (value) =>
  /^\d{1,2}(\.\d|\.)?$/.test(value) ? undefined : "not a number";
const numberWith4digits = (value) =>
  /^\d{1,4}$/.test(value) ? undefined : "not a number";
const numberInRange = (a, b) => (value) => {
  const fValue = parseFloat(value);
  return a <= fValue && fValue <= b ? undefined : "not in range";
};
const combineValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );
const wattValidator = combineValidators(
  required,
  numberWith4digits,
  numberInRange(0, 10000)
);
const hourValidator = combineValidators(
  required,
  numberWith2digits1dp,
  numberInRange(0, 24)
);

export function Modal({ open, setOpen, initialData, onSubmit, onDelete }) {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(initialData);
  useEffect(() => setData(initialData), [initialData]);
  const error =
    wattValidator(data.watt) ||
    hourValidator(data.hours) ||
    required(data.name) ||
    required(data.volt);
  return (
    <ModalBase
      open={open}
      setOpen={setOpen}
      canSubmit={error === undefined}
      onSubmit={() => onSubmit(data)}
      halfScreen={true}
    >
      <input
        type="text"
        className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight my-4"
        placeholder="Name"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
      <input
        type="text"
        className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight my-4"
        placeholder="Stunden / Tag"
        value={data.hours}
        onChange={(e) => /^(\d|\.)*$/.test(e.target.value) ? setData({ ...data, hours: e.target.value }) : null}
      />
      <input
        type="text"
        className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight my-4"
        placeholder="Watt*"
        value={data.watt}
        onChange={(e) => /^\d*$/.test(e.target.value) ? setData({ ...data, watt: e.target.value }) : null}
      />
      <span
        className="underline hover:no-underline cursor-pointer my-4 text-base-mobile md:text-base"
        onClick={() => setExpand((v) => !v)}
      >
        * Wo finde ich diese Infos zu meinem Gerät?
      </span>
      <div className={`${expand ? "" : "hidden"} mb-4`}>
        <div className="text-base md:text-base">
          <p className="mb-2">
            Gebe die Leistung (Watt) des Verbrauchers an. Diesen findest du
            in der Gebrauchsanweisung oder auf dem Gerät/Netzteil. Du kannst
            die Leistung (Watt) auch berechnen: Ampere x Volt = Watt.
          </p>
          <p className="mb-2">Beispiel: 2.8 A x 230 V = 644 W. </p>
          <p>
            Gebe auch an, ob das Gerät mit 230 V oder 12 V betrieben wird.
          </p>
        </div>
      </div>
      <div className="flex flex-row">
        <span className="mr-2 mb-2">Betriebsspannung:</span>
      </div>
      <div className="flex flex-row items-start items-center mb-4">
        <div
          className={`border w-24 text-center
                    px-4 py-2 mr-2 mb-2
                    cursor-pointer
                    shadow-min hover:shadow-big
                    transition-shadow duration-300
                    ${
                      data.volt === 230
                        ? "border-sun-yellow"
                        : "border-midnight"
                    }`}
          onClick={() => setData({ ...data, volt: 230 })}
        >
          230 Volt
        </div>
        <div
          className={`border w-24 text-center
                    px-4 py-2 mr-2 mb-2
                    cursor-pointer
                    shadow-min hover:shadow-big
                    transition-shadow duration-300
                    ${
                      data.volt === 12
                        ? "border-sun-yellow"
                        : "border-midnight"
                    }`}
          onClick={() => setData({ ...data, volt: 12 })}
        >
          12 Volt
        </div>
      </div>
      {onDelete !== undefined ? (
        <button
          className="underline cursor-pointer text-base-mobile md:text-base"
          onClick={() => {
            onDelete(initialData);
            setOpen(false);
          }}
        >
          Gerät entfernen
        </button>
      ) : null}
    </ModalBase>
  );
}

export default Modal;
