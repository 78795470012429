import { useState, useEffect } from "react";
import ModalBase from "./ModalBase";

const required = (value) => (value ? undefined : "required");

export function Modal({ open, setOpen, initialData, onSubmit }) {
  const [data, setData] = useState(initialData);
  useEffect(() => setData(initialData), [initialData]);
  const error = required(data.name);
  return (
    <ModalBase
      open={open}
      setOpen={setOpen}
      canSubmit={error === undefined}
      onSubmit={() => onSubmit(data)}
      halfScreen={true}
    >
      <input
        type="text"
        className="w-full bg-white pl-2 focus:outline-none placeholder-gray-500 placeholder-opacity-50 focus:placeholder-opacity-0 border-b border-midnight mb-4"
        placeholder="Installationsort eingeben"
        value={data.name}
        onChange={(e) => setData({ ...data, name: e.target.value })}
      />
    </ModalBase>
  );
}

export default Modal;
