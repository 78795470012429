import * as React from "react";

const SvgTv = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.334 7h-6.517l2.675-2.675-1.65-1.65L14 6.517l-3.842-3.842-1.65 1.65L11.185 7H4.667a2.336 2.336 0 0 0-2.333 2.333v12.834A2.336 2.336 0 0 0 4.667 24.5h18.666a2.336 2.336 0 0 0 2.334-2.333V9.333A2.336 2.336 0 0 0 23.333 7ZM4.666 22.167V9.333h18.666l.003 12.834H4.666Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTv;
