import * as React from "react";
import * as icons from "../icons";
import Summary from "./Summary";

export function Modal({ open, setOpen }) {
  return (
    <div className={`
      w-full md:w-1/2 absolute
      inset-0 ${open ? 'translate-x-0' : '-translate-x-full'}
      transition-transform duration-300
      bg-sun-yellow
      z-10
    `}>
      <div className="container-1/2 ml-auto p-8 md:pr-32 2xl:pl-0">
        <button className="absolute right-8 top-8 cursor-pointer outline-none" onClick={() => setOpen(false)}>
          <icons.Close className="h-8 w-8" />
        </button>
        <h1 className="mt-24 mb-8 text-xl font-bold">Deine Angaben</h1>
        <Summary />
        <button className="mt-8 text-xl underline underline-offset-1 cursor-pointer outline-none" onClick={() => setOpen(false)}>
          schliessen
        </button>
      </div>
    </div>
  );
}

export default Modal;
