import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

export function Modal({
  open,
  setOpen,
  canSubmit,
  onSubmit,
  children,
  hideSubmit = false,
  hideClose = false,
  halfScreen = false,
  closeOnSubmit = true,
}) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`fixed z-20 inset-0 ${halfScreen ? 'md:z-10 md:left-1/2' : ''} overflow-y-auto`}
        initialFocus={cancelButtonRef}
        onClose={() => setOpen(false)}
      >
        <div
          className="flex items-center justify-center
          min-h-screen
          p-4 sm:p-0
          text-center"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={`fixed inset-0 ${halfScreen ? 'md:left-1/2' : ''} bg-milk-white bg-opacity-75 transition-opacity`} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg
              relative
              text-left
              text-base-mobile md:text-base
              border border-black
              overflow-hidden shadow-xl
              transform transition-all
              sm:my-8 sm:align-middle sm:max-w-sm sm:w-full
              pt-6"
            >
              <div
                className={`absolute top-2 right-2 ${
                  hideClose ? "hidden" : ""
                }`}
              >
                <button className="outline-none" ref={cancelButtonRef} onClick={() => setOpen(false)}>
                  <XIcon className="h-8 w-8" strokeWidth={1} />
                </button>
              </div>
              <div className="bg-white p-4 flex flex-col">{children}</div>
              <div className={`${hideSubmit ? "hidden" : ""}`}>
                <button
                  type="button"
                  className="w-full inline-flex justify-center items-center bg-sun-yellow py-2 cursor-pointer disabled:bg-white disabled:text-silver outline-none"
                  disabled={!canSubmit}
                  onClick={() => {
                    onSubmit();
                    if (closeOnSubmit) {
                      setOpen(false);
                    }
                  }}
                >
                  bestätigen
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
