import * as React from "react";

const SvgSnowflake = (props) => (
  <svg
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 3.3a1.05 1.05 0 0 1 1.04.909l.01.142v3.321l2.108-1.852a1.05 1.05 0 0 1 1.386 1.576l-3.494 3.072v2.982h2.983l3.073-3.494a1.05 1.05 0 0 1 1.358-.19l.125.094a1.05 1.05 0 0 1 .189 1.358l-.094.124-1.855 2.107 3.322.001a1.05 1.05 0 0 1 1.04.907l.009.143a1.05 1.05 0 0 1-.907 1.04l-.143.01h-3.325l1.855 2.111a1.05 1.05 0 0 1 .014 1.372l-.11.11a1.05 1.05 0 0 1-1.371.015l-.111-.11-3.073-3.498h-2.982l.001 2.983 3.498 3.073a1.05 1.05 0 0 1 .19 1.358l-.094.124a1.05 1.05 0 0 1-1.358.19l-.124-.094-2.113-1.856v3.323a1.05 1.05 0 0 1-.909 1.04l-.142.009a1.05 1.05 0 0 1-1.04-.907l-.01-.143v-3.322L10.84 23.18a1.05 1.05 0 0 1-1.386-1.576l3.493-3.073V15.55h-2.734l-3.317 3.777a1.05 1.05 0 0 1-1.578-1.386l2.1-2.39H3.85a1.05 1.05 0 0 1-1.04-.908L2.8 14.5a1.05 1.05 0 0 1 .907-1.04l.143-.01H6.92L5.32 11.62a1.05 1.05 0 1 1 1.578-1.386l2.818 3.216h3.231v-2.982L9.454 7.392a1.05 1.05 0 0 1-.189-1.358l.094-.123a1.05 1.05 0 0 1 1.358-.189l.123.094 2.11 1.855V4.35a1.05 1.05 0 0 1 .773-1.012l.135-.028L14 3.3Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSnowflake;
