import * as React from "react";

const SvgRoofFlat2 = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="roof-flat-2_svg__a" fill="#fff">
      <rect x={4.353} y={7.167} width={15.294} height={12.833} rx={0.8} />
    </mask>
    <rect
      x={4.353}
      y={7.167}
      width={15.294}
      height={12.833}
      rx={0.8}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#roof-flat-2_svg__a)"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M2.75 5.25h18.5"
    />
    <mask id="roof-flat-2_svg__b" fill="#fff">
      <rect x={9} y={13} width={6} height={7} rx={0.8} />
    </mask>
    <rect
      x={9}
      y={13}
      width={6}
      height={7}
      rx={0.8}
      stroke="currentColor"
      strokeWidth={3}
      mask="url(#roof-flat-2_svg__b)"
    />
  </svg>
);

export default SvgRoofFlat2;
