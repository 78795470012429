import * as React from "react";

const SvgWater = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 22.333a6.667 6.667 0 0 1-6.667-6.666c0-2.919 2.259-5.253 4.245-7.392L14 5.667l2.423 2.608c1.986 2.14 4.244 4.474 4.244 7.392A6.667 6.667 0 0 1 14 22.333v0Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgWater;
