import * as React from "react";

const SvgBalkon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M166.24 116.52H136V44.56c0-2.94-2.38-5.46-5.46-5.46H47.1c-2.94 0-5.46 2.38-5.46 5.46v71.96H11.4c-2.94 0-5.46 2.38-5.46 5.46v53.76c0 2.94 2.38 5.46 5.46 5.46h154.98c2.94 0 5.46-2.38 5.46-5.46v-53.76c-.14-2.94-2.52-5.46-5.6-5.46zM52.56 84.18H83.5v32.34H52.56V84.18zm41.72 0h30.94v32.34H94.28V84.18zm30.8-34.16V73.4H94.14V50.02h30.94zm-41.72 0V73.4h-30.8V50.02h30.8zm77.56 120.26H16.72V144.8h144.06v25.48zm0-36.4H16.72v-6.44h144.06v6.44z" />
  </svg>
);

export default SvgBalkon;
