import * as React from "react";

const SvgKuehlschrank = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 212.6 212.6"
    style={{
      enableBackground: "new 0 0 212.6 212.6",
    }}
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M139.5 32.9H73c-7.7 0-14 7.1-14 15.9v102.7c0 8.6 6 15.6 13.4 15.9v8.3c0 2.2 1.8 4 4 4H136c2.2 0 4-1.8 4-4v-8.3c7.4-.3 13.4-7.3 13.4-15.9V48.9c.1-8.8-6.1-16-13.9-16zM73 40.7h66.5c3.4 0 6.2 3.7 6.2 8.2v31.8h-79V48.9c.1-4.5 3-8.2 6.3-8.2zm7.5 131.1v-4.3h51.6v4.3H80.5zm59-12H73.1c-3.4 0-6.2-3.7-6.2-8.2V88.4h79v63.1c-.1 4.5-3 8.3-6.4 8.3z" />
    <path d="M75.2 48.4H83v26.4h-7.8zM75.2 94.4H83v26.4h-7.8z" />
  </svg>
);

export default SvgKuehlschrank;
