import * as React from "react";

const SvgLaptop = (props) => (
  <svg
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.667 8.167A2.333 2.333 0 0 1 7 5.833h14a2.333 2.333 0 0 1 2.333 2.334v10.5H4.667v-10.5ZM2.333 22.167h23.334"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLaptop;
