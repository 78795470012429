import React from "react";
import { BaseScopeRow, BodyLeft, BodyRight } from "./Base";
import * as icons from "../icons";
import { StateContext, DispatchContext } from "../Context";
import { ButtonIconWide } from "../components/Button";

export const scopes = [
  {
    type: "campervan",
    title: "Camper / Wohnwagen / Wohnmobil",
    icon: icons.Campervan,
  },
  {
    type: 'plugin',
    title: 'Balkonkraftwerke / Plug In',
    icon: icons.Balkon,
  },
  {
    type: "villa",
    title: "Alphütte / Maiensäss / Rustico",
    icon: icons.House,
  },
  {
    type: 'schiffe',
    title: 'Boot / Schiffe',
    icon: icons.Boot,
  },
  /*
  {
    type: 'e-car',
    title: 'Solaranlage für Elektro-Fahrzeug',
    icon: icons.Solarpanel,
  },
  {
    type: 'home',
    title: 'Plug&Play-Solaranlage für Zuhause',
    icon: icons.Plug,
  },
  {
    type: 'extend',
    title: 'Erweiterung bestehende Solaranlage',
    icon: icons.SolarpanelPlus,
  },*/
];

export const scopeNames = scopes.reduce((acc, cur, i) => {
  acc[cur.type] = cur.title;
  return acc;
}, {});

function ScopeSelect({ next }) {
  const { setScope } = React.useContext(DispatchContext);
  const { scope } = React.useContext(StateContext);
  return (
    <div className="w-auto lg:max-w-[600px] flex flex-col mx-auto items-center">
      {scopes.map(({ type, title, icon: Icon }, index) => (
        <ButtonIconWide
          key={index}
          icon={Icon}
          active={scope === type}
          onClick={() => {
            setScope(type);
            next(type);
          }}
        >
          {title}
        </ButtonIconWide>
      ))}
    </div>
  );
}

function ScopePage(props) {
  const { scope } = React.useContext(StateContext);
  return (
    <BaseScopeRow
      scopeTitle="Anwendungsbereich: Einsatzort"
      canGoNext={scope !== undefined}
      {...props}
      next={() => props.next(scope)}
    >
      <BodyLeft className="bg-white">
        <BodyLeft.Title>
          Wo willst du deine Solaranlage einsetzen?
        </BodyLeft.Title>
        <BodyLeft.Subtitle>
          Wähle deinen Anwendungsbereich.
        </BodyLeft.Subtitle>
      </BodyLeft>
      <BodyRight className="bg-white">
        <ScopeSelect {...props} />
      </BodyRight>
    </BaseScopeRow>
  );
}

export default ScopePage;
